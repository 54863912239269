import { isElectronApp } from './raw_flags';

/**
 * @param {Object} userData
 * @param {string=} userData.userName
 * @param {string=} userData.email
 * @return {Parameters<import('./data').createSnippet>[0][]}
 */
export function demoSnippetConfigs({ userName, email }) {
  
  return [
    {
      name: 'Demo - Plain text',
      shortcut: '/sig',
      order: 1,
      type: 'text',
      text: `${userName || 'Panda Beariston'}${email ? ` - ${email}` : ''}
Director of Bamboo Research
Text Blaze Inc.`
    },
    {
      name: 'Demo - Styled Text',
      shortcut: '/style',
      order: 2,
      type: 'html',
      text: `<p>Snippets can contain <b>bold</b>, <u>underline</u> and <b><span style="color: red">c</span><span style="color: darkorange">o</span><span style="color: goldenrod">l</span><span style="color: green">o</span><span style="color: blue">r</span><span style="color: violet">s</span></b>. Here are some other things you can use to style your snippets:</p><ol>
<li>lists like this one</li>
<li><span class="ql-font-impact">IMPACTFUL</span> <span class="ql-font-georgia">FONTS</span></li>
<li>links like <a href="https://blaze.today">blaze.today</a></li>
<li>and images (Pro version only)</li>
</ol>`
    },
    {
      name: 'Demo - Time and Dates',
      shortcut: '/d8',
      order: 3,
      type: 'html',
      text: `<p>Text Blaze can insert the current date. Today is {time: MMMM D, YYYY}. You can customize the date formatting. For example, {time: MMM D} or {time: YYYY-MM-DD}.</p><br/>

<p>You can also insert a date relative to the present. In 2 weeks, it will be {time: MMMM D, YYYY; shift=2W}; and in one month it will be {time: MMMM D, YYYY; shift=1M}.</p><br/>

<p>Learn more about dates and times here: <a href="https://blaze.today/commands/time/">https://blaze.today/commands/time/</a></p>`
    },
    {
      name: 'Demo - Cursor',
      shortcut: '/cursor',
      order: 4,
      type: 'text',
      text: 'You can place the mouse cursor anywhere in a snippet after insertion (like here -->{cursor}<--)'
    },
    {
      name: 'Demo - Forms',
      shortcut: '/form',
      order: 5,
      type: 'html',
      text: `<p>Hi {formtext: name=your name},</p><br/>

<p>Text Blaze has forms you can fill out when inserting a snippet. Forms can contain text fields, check boxes and dropdown menus.</p><br/>

<p>Let's try this out:</p><br/>

<p>A customer bought {formtext: name=quantity; default=25} widgets at a price of $\{formmenu: name=price; 12.00; default=24.00; 48.00} per widget.</p><br/>

<p>The total price of the widgets is calculated with a formula: $\{=price * quantity}.</p><br/>

<p>{formtext: name=your name}, you can learn more about forms here: <a href="https://blaze.today/guides/forms">https://blaze.today/guides/forms/</a></p>`
    }];
}

/**
 * @type {Parameters<import('./data').createSnippet>[0]}
 */
export const demoAISnippetConfig = {
  name: 'Demo - AI snippet',
  shortcut: '/ai',
  order: 6,
  type: 'text',
  text: 'Summarize this ' + isElectronApp() ? 'application' : 'webpage',
  options: {
    is_ai: true,
    include_page_context: true,
    ai_action: {
      updated_at: Date.now(),
      action: 'write',
    },
    // TODO: remove polish mode from all these snippet configs
    // once new extension is updated (~end July 2024)
    polish_mode: false,
  }
};

/**
 * @return {Parameters<import('./data').createSnippet>[0][]}
 */
export function aiBlazeExtraDemoSnippetConfigs() {
  return [
    {
      name: 'Respond to an email',
      shortcut: '/email',
      order: 2,
      type: 'html',
      text: 'Respond to this email',
      options: {
        is_ai: true,
        include_page_context: true,
        ai_action: {
          updated_at: Date.now(),
          action: 'write',
        },
        polish_mode: false,
      }
    },
    {
      name: 'Translate text',
      shortcut: '/translate',
      order: 3,
      type: 'html',
      text: 'Translate this text into {formmenu: default=English; Spanish; Mandarin Chinese; French; German; Arabic}',
      options: {
        is_ai: true,
        include_page_context: true,
        ai_action: {
          updated_at: Date.now(),
          action: 'polish',
        },
        polish_mode: true,
      }
    },
    {
      name: 'List typos',
      shortcut: '/typos',
      order: 4,
      type: 'text',
      text: 'Create a list of any spelling or grammatical mistakes.',
      options: {
        is_ai: true,
        include_page_context: true,
        ai_action: {
          updated_at: Date.now(),
          action: 'write',
        },
        polish_mode: false,
      }
    },
    {
      name: 'Improve my writing',
      shortcut: '/edit',
      order: 5,
      type: 'text',
      text: 'Edit my writing to make it more {formmenu: default=Professional; Concise; Friendly}',
      options: {
        is_ai: true,
        include_page_context: true,
        ai_action: {
          updated_at: Date.now(),
          action: 'polish',
        },
        polish_mode: true,
      }
    },
    {
      ...demoAISnippetConfig,
      name: 'Summarize the page',
      shortcut: '/tldr',
      order: 1
    },
  ];
}