import React, { useContext, useEffect, useRef, useState, Suspense } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill-better-table/src/assets/quill-better-table.scss';
import Link from 'quill/formats/link';
import ImageBlot, { IMAGE_URL_PREFIX } from './Image';
import AddIcon from '@mui/icons-material/NoteAddOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import RestoreIcon from '@mui/icons-material/Restore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getData } from '../DataDialog/DataDialog';
import { toast } from '../../message';
import './quill.css';
import './SnippetEditor.css';
import { log } from '../../logging/logging';
import { limitationsState } from '../Version/limitations';
import { isPro, usersSettingsRef } from '@store';
import ImageUploader from '../ImageUploader/ImageUploader';
import { fontSizes, fonts, registerWithQuill } from './fontConfig';
import { format } from 'd3-format';
import { imageConfig } from '../DataDialog/dialogConfigs';
import { SideBar } from './BottomToolbar';
import { sync } from '../../Sync/syncer';
import { orgId } from '../../flags';
import AIChatIcon from '@mui/icons-material/Chat';
import {
  useConnectedSettings,
  useIsMounted,
  useIsXShort,
  useTypedSelector,
  useTypedSelectorShallowEquals
} from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { tokenize } from '../../snippet_processor/Parser';
import { Environment } from '../../snippet_processor/DataContainer';
import {
  beforeEmbeddedCommandClose,
  convertDeltaToString,
  escapeFormName,
  expandDeltaContents,
  getAttributeData,
  getCollapsedData,
  getQuill,
  tokenizeAttributes,
  tokenizeData,
  updateAttributeTree
} from './editor_utilities';
import { EmbeddedCommandContents } from './EmbeddedCommand/EmbeddedCommand';
import createQuillEditor from './quill_editor';
import { addBreadcrumb } from '@sentry/browser';
import { convertImageToInsertImage } from '../../import_export/DeltaImport';
import T from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { eagerlyLoadImport, storage } from '../../utilities';
import { arrayUnion } from 'firebase/firestore';
import useCloseWarning from '../../hooks/useCloseWarning';
import {
  SUPPORTED_IMAGE_EXTENSIONS,
  arrayBufferToString,
  uploadImage,
  imageMimeTypeToExtension
} from '../../image_utilities';
import CommandError from './CommandError';
import TableMenu from './Table/TableMenu';
import TableGridSelector from './Table/TableGridSelector';
import { getFullRegistry, getPlainRegistry, registerTableWithQuill } from './quillConfig';
import ReadonlyNotice from './ReadonlyNotice';
import SnippetChangesRequired from './SnippetChangesRequired';
import { TypedMenu } from './TypedItems';
import {
  Alert,
  Button,
  ClickAwayListener,
  debounce,
  Dialog,
  FormControlLabel,
  IconButton,
  Menu,
  Popper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from '@mui/material';
import AdvancedAISettingsIcon from '@mui/icons-material/SettingsSuggestOutlined';
import AIIcon from '@mui/icons-material/AutoFixHigh';
import { BetaChip, ProChip } from '../Version/VersionChip';
import { SnippetEditorContext } from '../Snippet/SnippetWrapper';
import { makeClassificationRequest } from '../AutoWriteChat/utilities';
import { ActionContext, SnippetContentContext } from '../AutoWriteChat/Context';
import AIInsertIcon from '@mui/icons-material/CreateOutlined';
import AIPolishIcon from '@mui/icons-material/AutoFixHighOutlined';
import Typer from '../Shortcut/Typer';
import EditIcon from '@mui/icons-material/Edit';
import PageChangesRequired from '../PageBlaze/PageChangesRequired';
import { getHighlightEnvironment, getRealRangeIndexFromNodes } from './highlighter';
import {
  changeVariableNameInDelta,
  getVariableCountInDelta, getVariableReplaceOrderFromDelta
} from './replace_variable_name';
import { getCollapsedCommandBlot, getCollapsedCommandByIndex, getHighlighter } from './highlighter_utilities';
import CircularProgress from '@mui/material/CircularProgress';
import useOnMount from '../../hooks/useOnMount';
import SnippetPreviewPanel from '../Snippet/SnippetPreviewPanel';
import { RemoteBottomStatusBar } from '../FormRenderer/FormRenderer';
import { isAiBlaze } from '../../aiBlaze';
import { isElectronFlag } from '../../raw_flags';


const AutoWrite = React.lazy(eagerlyLoadImport(() => import('./AutoWrite')));


// The imperative Quill definition has trouble with fast refresh so we need to:
//
// @refresh reset
//
// To enable fast refresh we would need to reset the dom before reinitializing quill
// on fast refresh. This doesn't seem possible without moving the quill stub out
// of JSX (which may be a good idea to do anyways due to the font toolbar issue).

registerWithQuill(Quill);
const QuillBetterTable = registerTableWithQuill();
QuillBetterTable.addDependentBlot(ImageBlot);

const Delta = Quill.import('delta');
let Parchment = Quill.import('parchment');

const tokenizeCounters = {};

const QUILL_COLOR_OPTIONS = ['rgb(0, 0, 0)','rgb(230, 0, 0)','rgb(255, 153, 0)','rgb(255, 255, 0)','rgb(0, 138, 0)','rgb(0, 102, 204)','rgb(153, 51, 255)','rgb(255, 255, 255)','rgb(250, 204, 204)','rgb(255, 235, 204)','rgb(255, 255, 204)','rgb(204, 232, 204)','rgb(204, 224, 245)','rgb(235, 214, 255)','rgb(187, 187, 187)','rgb(240, 102, 102)','rgb(255, 194, 102)','rgb(255, 255, 102)','rgb(102, 185, 102)','rgb(102, 163, 224)','rgb(194, 133, 255)','rgb(136, 136, 136)','rgb(161, 0, 0)','rgb(178, 107, 0)','rgb(178, 178, 0)','rgb(0, 97, 0)','rgb(0, 71, 178)','rgb(107, 36, 178)','rgb(68, 68, 68)','rgb(92, 0, 0)','rgb(102, 61, 0)','rgb(102, 102, 0)','rgb(0, 55, 0)','rgb(0, 41, 102)','rgb(61, 20, 102)'];


function defaultColors() {
  return QUILL_COLOR_OPTIONS.map(c => <option key={c} value={c} label={c} />);
}


/** @type {React.CSSProperties} */
const AI_EDITOR_STYLES = {
  borderRadius: 10,
  overflow: 'auto',
  border: '1px solid #ddd',
  zoom: 1.2
};


let defaultSanitize = Link.sanitize;
/**
 * @type {typeof Link.sanitize}
 */
Link.sanitize = function (url) {
  let val = url.trim();
  let valLC = val.toLowerCase();

  if (!valLC.startsWith('mailto:') && !valLC.startsWith('tel:')) {
    if (!/^(?:[a-z]+:)?\/\//.test(valLC)) {
      // if no protocol is specified , use `https`
      val = 'https://' + val;
    }
  }

  return defaultSanitize.call(this, val);
};


const RANDOM_EXAMPLE_CHOICE = Math.floor(Math.random() * 5);

/**
 * @param {{ quill: Quill, examples: { prompt: string, initText?: string, shortcut: string, postText: string }[], onExampleSelect: (prompt: string, page_context: boolean) => void, logFn: Function }} props
 */
function AIExamples({ quill, examples, onExampleSelect, logFn }) {
  const [index, setIndex] = useState(RANDOM_EXAMPLE_CHOICE);
  const item = examples[index % examples.length];
  const [undoShown, setShowUndo] = useState(false);
  const undoTimeout = useRef(null);
  const previousContent = useRef('');

  function tryCurrentExample() {
    logFn({
      action: 'AI snippet example selected',
      label: {
        prompt: item.prompt,
      },
    });
    const currentLength = quill.getLength(),
      val = quill.getText(0, currentLength);
    previousContent.current = val;
    onExampleSelect(item.prompt, true);
    if (undoTimeout.current) {
      clearTimeout(undoTimeout.current);
    }
    undoTimeout.current = setTimeout(() => {
      setShowUndo(false);
      undoTimeout.current = null;
    }, 10000);
    setShowUndo(true);
  }

  return <Box sx={{
    marginTop: '20px',
    marginBottom: '80px',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,.04)',
    padding: '24px',
    borderRadius: '20px',

  }}>
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: 4,
        alignItems: 'center',
        mb: 2
      }}>
      <T
        variant="h6"
      >Example prompts</T>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '10px',
        alignItems: 'center'
      }}>
        <Button
          size="small"
          variant="text"
          startIcon={<EditIcon />}
          onClick={() => tryCurrentExample()}
        >Try this example</Button>
        
        {undoShown && <Button size="small" variant="text" startIcon={<RestoreIcon />} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clearTimeout(undoTimeout.current);
          undoTimeout.current = null;
          setShowUndo(false);
          quill.setText(previousContent.current, 'user');
        }}>Undo this change</Button>}
      </Box>

      <div style={{ flex: 1 }}></div>

      <Button  size="small" variant="text" endIcon={<ArrowForwardIcon />} onClick={(e) => {
        setIndex(index => index + 1);
        clearTimeout(undoTimeout.current);
        undoTimeout.current = null;
        setShowUndo(false);
        e.preventDefault();
        e.stopPropagation();
      }}>Next example</Button>
    </Box>
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '20px',
        alignItems: 'stretch'
      }}>
      
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}>
        <span
          style={Object.assign({
            flex: 1,
            padding: 16,
            fontSize: '92%',
            color: '#555',
            display: 'flex',
            width: '100%',
            cursor: 'pointer',
            background: 'white'
          }, AI_EDITOR_STYLES)}
          onClick={() => {
            tryCurrentExample();
          }} 
        >
          <div style={{
            borderTop: '1px solid #ddd !important',
            flex: 1
          }}>
            {item.prompt}
          </div>
        </span>
      </Box>

      <Box
        sx={{
          fontSize: '150%',
          display: 'inline-flex',
          alignItems: 'center'
        }}>
        <ArrowForwardIcon />
      </Box>

      <AIExample
        key={index}
        initText={item.initText}
        shortcut={item.shortcut}
        postText={item.postText}
      />

    </Box>
  </Box>;
}

/**
 * @param {object} props 
 * @param {string=} props.initText
 * @param {string} props.shortcut
 * @param {string} props.postText
 * @returns 
 */
function AIExample(props) {
  const isMounted = useIsMounted();
  let [mode, setMode] = useState(props.initText ? 'INIT' : 'SHORTCUT');

  /**
   * @param {string} mode 
   */
  function updateMode(mode) {
    if (isMounted.current) {
      setMode(mode);
    }
  }
  
  return <div style={{
    position: 'relative'
  }}>
    <div style={{
      width: 250,
      borderRadius: 4,
      backgroundColor: 'white',
      position: 'relative'
    }}>
      
      <div style={{
        fontSize: '16px',
        padding: 4,
        border: 'solid 1px #ccc',
        borderRadius: 4,
        height: 110,
        overflowY: 'scroll'
      }}>
        {mode === 'INIT' && <Typer
          onTypingDone={() => setTimeout(() => updateMode('SHORTCUT'), 1500)}
          delay={500}
          speed={50}
        >
          {props.initText ? props.initText.split('\n').map((x, i) => <p key={i}>{x}</p>) : ''}
        </Typer>}
      
        {mode === 'SHORTCUT' && <>{props.initText ? props.initText.split('\n').map((x, i) => <p key={i}>{x}</p>) : ''}<b>
          <Typer
            onTypingDone={() => setTimeout(() => updateMode('POST'), 1500)}
            speed={200}
          >
            {props.shortcut || ''}
          </Typer>
        </b>
        </>}
        {mode === 'POST' && 
          <span style={{
            textShadow: ` 
            2px 0px 10px #d1e9f5,
            4px 0px 10px #a6dbd8,
            6px 0px 10px #e8fffa
          `
          }}><Typer
              onTypingDone={() => setTimeout(() => {
                if (props.initText) {
                  updateMode('INIT');
                } else {
                  updateMode('SHORTCUT');
                }
              }, 3500)}
              speed={10}
            >{props.postText || ''}
            </Typer></span>}
        
      </div>
    </div>
    <Box
      sx={{
        position: 'absolute',
        right: 20,
        top: 100,
        width: '220px',
        border: 'solid 1px #ccc',
        padding: 1,
        overflow: 'hidden',
        fontSize: '90%',
        background: 'linear-gradient(to right, #e6f0ff, #e8fffa)',
        borderRadius: '4px'
      }}
    >
      <b>Step {(props.initText ? ['INIT', 'SHORTCUT', 'POST'] : ['SHORTCUT', 'POST']).indexOf(mode) + 1}</b> {mode === 'INIT' && 'Enter text to be polished into any text box ' + isElectronFlag ? 'in any application' : 'on any webpage'}
      {mode === 'SHORTCUT' && (props.initText ? 'Next, type your snippet shortcut' : 'Type your shortcut into any text box ' + isElectronFlag ? 'in any application' : 'on any webpage')}
      {mode === 'POST' && (props.initText ? 'AI polishes your text 🔥' : 'AI writes your text 🔥')}
    </Box>
  </div>;
}

/**
 * @typedef {object} EditorContextType 
 * @property {string[]=} names
 * @property {import("../../snippet_processor/ParseNode").default=} token
 * @property {boolean=} editingAttribute
 * @property {{data: import("./editor_utilities").CollapsedDataType, rootData: import("./editor_utilities").CollapsedDataType, attributeName?: string, node: HTMLElement & {_BlazeErrorCallbackFn?: function}}=} collapsedSelected
 * @property {boolean=} hasFocus
 * @property {string=} tag
 * @property {number=} forceCollapseState
 * @property {import("../../snippet_processor/ParserUtils").NodeAttribute=} attribute
 * @property {object=} types
 * @property {object=} activeTypes
 * @property {number=} realRangeIndex
 * @property {number=} rangeIndex
 * @property {import("../../snippet_processor/ParseNode").default[]=} nodesData
 */

const DISMISSED_CHIPS_NOTIFICATION_KEY = '%APP_snippet_editor_chips_2';

/**
 * @param {object} props
 * @param {string=} props.snippetId
 * @param {string=} props.groupId
 * @param {boolean=} props.quickentry
 * @param {boolean=} props.isAI
 * @param {boolean=} props.includePageContext
 * @param {'write'|'polish'|'chat'=} props.aiAction
 * @param {{id: string, delta: (DeltaType | import('quill/core').Delta)}=} props.value
 * @param {(type: 'delta'|'quickentry'|'include_page_context'|'ai_action_user'|'polish_mode', value: any, id?: any) => void} [props.onChange]
 * @param {boolean} props.editable
 * @param {boolean} props.owner
 * @param {boolean=} props.preview
 * @param {boolean=} props.isAddon
 * @param {boolean=} props.isPage
 * @param {boolean=} props.xSharingDisabled
 * @param {boolean=} props.userAddonsEnabled
 * @param {boolean=} props.isAssociatedToUs
 * @param {boolean=} props.connectedEditingBlocked
 * @param {import('../Version/usageLimitations').LimitationsDef=} props.limitations
 * @param {function=} props.handleNames
 * @param {string[]=} props.formNames
 * @param {boolean=} props.showAutoSaveNotice
 * @param {string=} props.placeholder
 * @param {boolean=} props.hidden - true if rendered but not displayed
 * @param {boolean=} props.hideViewerMessage
 * @param {boolean=} props.showCommands
 * @param {function=} props.setShowCommands
 * @param {number=} props.createdAt
 * @param {string=} props.shortcut
 * @param {function=} props.openScratchPad
 * @param {boolean=} props.hideCommandsListToolbar
 * @param {React.CSSProperties=} props.editorStyle
 * @param {React.ReactElement=} props.draftComponent
 * @param {number=} props.commandErrorBottom
 * @param {(errors: Set<string>) => any} [props.onEmbeddedCommandError]
 */
export default function SnippetEditor(props) {
  const snippetEditorContext = useContext(SnippetEditorContext);
  let [showTypedMenu, setShowTypedMenu] = useState(null);
  const [focusedInEditor, setFocusedInEditor] = useState(false);
  const [promptPreviewVisible, setPromptPreviewVisible] = useState(false);
  const [remoteItems, setRemoteItems] = useState([]);
  const [snippetHasCommands, setSnippetHasCommands] = useState(false);
  const [showAdvancedAISettings, setShowAdvancedAISettings] = useState(null);
  let {
    activeAddons,
    limitations,
    showDynamicBadge,
    showAddonHighlight
  } = useTypedSelector((store) => {
    let createdAt = store.userState && store.userState.firebaseMetadata && new Date(store.userState.firebaseMetadata.creationTime).getTime();
    let limitations = props.limitations || limitationsState(store);
  
    // Stuart testing a higher char limitation
    // TODO make this for all enterprise users if it works well with Stuart
    let myOrgId = orgId(store);
    if (myOrgId && myOrgId.includes('3EyxbYshqe3qfP')) {
      limitations.MAX_SNIPPET_SIZE = 100000; // 100K
    }
    
    let addons = sync.activeAddons();
  
    return {
      activeAddons: addons,
      limitations,
      
      showDynamicBadge: createdAt > 1581377111798 /** Don't show prior to feature added (from 11 Feb 2020) */ && store.userState.settingsLoaded && (!store.userState.dismissed_notifications || !store.userState.dismissed_notifications.includes('%APP_dynamic_badge')),
      
      // Only show if: haven't dismissed addon notificaiton,
      showAddonHighlight: store.userState.settingsLoaded && (!store.userState.dismissed_notifications || !store.userState.dismissed_notifications.includes('%APP_addon_highlight'))
        // doesn't have addons,
        && (!(addons && Object.keys(addons).length))
        // and email is verified,
        && store.userState.emailVerified
        // account is over a day old (don't want too much noise to start)
        && (Date.now() - createdAt) > 24 * 60 * 60 * 1000
    };
  });

  useEffect(() => {
    if (!snippetEditorContext.subAttributeSelected) {
      return;
    }
    setContext(c => {
      if (!c?.collapsedSelected) {
        return c;
      }
      let attributesPath = snippetEditorContext.subAttributeSelected.attributesPath;
      let attributeName = snippetEditorContext.subAttributeSelected.attributeName;
      let currentData = c.collapsedSelected.data;
      let newData = getAttributeData(currentData, attributesPath);
      return {
        ...c,
        collapsedSelected: {
          data: newData,
          attributeName: attributeName,
          node: c.collapsedSelected.node,
          rootData: c.collapsedSelected.rootData
        }
      };
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippetEditorContext.subAttributeSelected]);

  let isXShort = useIsXShort();

  let autowriteRef = useRef(null);

  let valueIds = useRef(/** @type {string[]} */ ([]));

  let uploaderRef = useRef(null);


  let quill = useRef(/** @type {Quill} */ (null));
  let highlighter = useRef(/** @type {import('./highlighter').default} */ (null));
  let editorHasBeenFocused = useRef(/** @type {boolean} */ (null));
  let clearSuggestionFn = useRef(/** @type {function} */ (null));
  let [autowriteData, setShowAutoWrite] = useState(/** @type {{ content: string, selection: string, }} */ (null));
  let [isAIGenerating, setIsAIGenerating] = useState(false);
  let [openedAIWriteOnce, setOpenedAIWriteOnce] = useState(false);
  let aiWriteAnchor = useRef(/** @type {HTMLElement} */ (null));

  let quillRef = useRef(null);
  let quillToolbarRef = useRef(null);
  let quillContainerRef = useRef(null);

  let [context, setContextBase] = useState(/** @type {EditorContextType} */ (null));

  /**
   * @param {(prevValue: typeof context) => typeof context} fn
   */
  const setContext = (fn) => {
    const clearError = () => {
      embeddedCommandError.current = new Set();
      props.onEmbeddedCommandError?.(new Set());
    };
    setContextBase((currentValue) => {
      const newValue = fn(currentValue);
      // Either command was never selected or it is same command
      if (currentValue?.collapsedSelected?.data?.meta?.id === newValue?.collapsedSelected?.data?.meta?.id) {
        return newValue;
      }

      const collapsedElement = currentValue?.collapsedSelected?.node;
      // If marked as collapsedSelected but node does exist on DOM, probably double clicked. Ignore the warning.
      if (collapsedElement && !collapsedElement?.isConnected) {
        clearError();
        return newValue;
      }
      const beforeCloseResponse = beforeEmbeddedCommandClose(embeddedCommandError.current);

      // No error
      if (!beforeCloseResponse) {
        return newValue;
      }
      beforeCloseResponse
        .then(() => {
          if (!isMounted.current) {
            return;
          }
          setContextBase(newValue);
          clearError();
        })
        .catch(() => null);
      // Update with previous value for now.
      return currentValue;
    });
  };

  let [characterCount, setCharacterCount] = useState(0);

  let isMounted = useIsMounted();

  const { warnOnExit: warnOnExitForImages, cancelWarningOnClose: cancelWarningOnCloseForImages } = useCloseWarning();
  const { warnOnExit: warnOnExitForCommand, cancelWarningOnClose: cancelWarningOnCloseForCommand } = useCloseWarning();
  const imagesUploadList = useRef([]);
  const tableMenuRef = useRef(/** @type {import('./Table/TableMenu').TableMenuRef} */ (null));
  const [gridSelectorAnchor, setGridSelectorAnchor] = useState(null);
  const [emojiSelectorAnchor, setEmojiSelectorAnchor] = useState(null);
  const embeddedCommandError = useRef(new Set());

  let {
    enabled: isCollapsedEnabled,
    showNotification: shouldShowChipsNotification
  } = useTypedSelectorShallowEquals((state) => {
    let stateOptions =  state?.userState?.options,
      enabled = stateOptions?.snippet_editing_chips_enabled_2,
      enabledBefore = stateOptions?.snippet_editing_chips_enabled,
      notificationDismissed;

    if (!state || !state.userState || !state.userState.settingsLoaded) {
      notificationDismissed = true;
    } else {
      notificationDismissed = (
        state.userState.dismissed_notifications &&
        state.userState.dismissed_notifications.includes(DISMISSED_CHIPS_NOTIFICATION_KEY)
      );
    }
    return {
      enabled: enabled !== false,
      showNotification: (
        enabledBefore === false 
        && typeof enabled === 'undefined'
        && !notificationDismissed
      )
    };
  });

  useEffect(() => {
    if (!shouldShowChipsNotification) {
      return;
    }
    showChipsNotification();

    // This notification is only needed once.
    storage.update(usersSettingsRef, {
      dismissed_notifications: arrayUnion(DISMISSED_CHIPS_NOTIFICATION_KEY)
    }, 'HIDE_AUTOSAVE');
  }, [shouldShowChipsNotification]);
    

  let logFn = (data) => {
    if (!props.preview) {
      log(data, {
        snippet_id: props.snippetId,
        group_id: props.groupId
      });
    }
  };

  useEffect(() => {
    if (quill.current) {
      if (props.editable) {
        quill.current.enable();
      } else {
        quill.current.disable();
      }
    }
    // eslint-disable-next-line
  }, [props.editable, quill.current]);
    
    
  useEffect(() => {
    if (quill.current && !valueIds.current.includes(props.value.id)) {

      addBreadcrumb({
        message: 'Remote contents change'
      });

      valueIds.current.push(props.value.id);


      highlighter.current.highlight(true, /** @type {any} */ (props.value.delta));

      setTimeout(() => updateCharacterCount(props.value.delta), 200);
      // @ts-ignore
      quill.current.history.clear();

    }
    // eslint-disable-next-line
  }, [props.value.id]);

  useDeepCompareEffect(() => {
    let newAddons = activeAddons || {};
    if (highlighter.current && Object.keys(newAddons).length !== Object.keys(highlighter.current.addons).length) {
      snippetEditorContext.setAddons(newAddons);
      highlighter.current.addons = newAddons;
      // TODO: there appears to be a bug in quill where this will trigger
      // an update with 'user' rather than 'api'/'silent' from the highlighter.
      // Causing the snippet to be saved when the dashboard is opened on the snippet.
      //
      // You have highlight:
      //   normal commands -> loaded addons -> highlighted addons (trigger update)

      highlighter.current.highlight(true);
    }
  }, [!!highlighter.current, Object.keys(activeAddons || {}).sort()]);

  const connectedSettings = useConnectedSettings({ groupId: props.groupId, });

  async function dialog(config) {
    return getData(Object.assign({
      icon: <AddIcon/>,
      context
    }, config, {
      title: <span>Insert <b>{config.title}</b></span>,
      FieldsWrapper: SnippetEditorContext.Provider,
      fieldsWrapperProps: {
        value: snippetEditorContext
      }
    }));
  }


  useEffect(() => {
    // needed to get quill to update the placeholder in AI snippets
    if (quillContainerRef.current) {
      quillContainerRef.current.firstChild?.firstChild?.setAttribute('data-placeholder', props.placeholder);
    }
  }, [props.placeholder]);

  useOnMount(() => {
    // Unmount the previous AI Write popup as user has loaded a new snippet now
    // Note: this does not cancel the running generation on the backend server.
    // We should do that if it actually affects our bill.
    // Question posted: https://community.openai.com/t/859904?u=gaurang
    setOpenedAIWriteOnce(false);
    let el = quill.current;
    let editorConfig = {
      quillNodeRef: quillRef.current,
      quillContainerNodeRef: quillContainerRef.current,
      placeholder: props.placeholder,
      quillModules: {
        history: {
          userOnly: true,
          // @ts-ignore
          delay: typeof vi !== 'undefined' && global.HISTORY_TESTING ? 1 : 1000
        },
        snippetsyntax: true,
        table: false,  // disable table module
        'better-table': {
          'onMenu': (params) => {
            tableMenuRef.current.show(
              params.evt.target,
              params
            );
          },
          /**
           * 
           * @param {string} message 
           * @param {('info' | 'warning' | 'danger')} type 
           */
          onMessage: (message, type) => {
            toast(message, {
              intent: type
            });
          }
        },
        uploader: {
          /**
           * 
           * @param {import('quill/core/selection').Range} range 
           * @param {File[]} files 
           */
          handler: (range, files) => {
            if (!isPro()) {
              warnImagesPro();
              return;
            }
            const promises = files.map(file => {
              return new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = e => {
                  resolve(e.target.result);
                };
                reader.readAsDataURL(file);
              });
            });
            Promise.all(promises).then(images => {
              const update = images.reduce((delta, image) => {
                return delta.insert({
                  'insert-image': {
                    url: image,
                    pasted: true
                  }
                });
              }, new Delta().retain(range.index).delete(range.length));
              el.updateContents(update, 'user');
              images.forEach((image) => {
                uploadPastedImage(image);
              });
            });
          }
        }
      },
      registry: getFullRegistry()
    };
    
    // Workaround to the problem of controlled inputs in React.
    // React removes the `selected` props from <option>'s that Quill relies on 
    // to restore the default selection in the font size and family menus.
    if (!props.isAI) {
      [...quillToolbarRef.current.querySelectorAll('[data-default-selected="true"]')].forEach(el => el.setAttribute('selected', 'selected'));

      editorConfig.quillModules.toolbar = {
        container: quillToolbarRef.current,
        handlers: {
          'image': () => {
            if (!isPro()) {
              warnImagesPro();
              return;
            }

            logFn({ category: 'Image', action: 'Open image dialog' });

            uploaderRef.current.selectImage().then(handleImageUpload).catch(() => {});
          },
          'color': (value) => {
            if (value === 'quill-custom-color') {
              if (!isPro()) {
                toast('Custom colors are only available to Pro users.', {
                  duration: 8000,
                  intent: 'danger',
                  upgrade: 'Upgrade to get them.'
                });
                return;
              }

              let picker = /** @type {HTMLInputElement} */ (document.getElementById('quill-custom-color-picker-color'));
              picker.click();

              if (!picker.getAttribute('data-used')) {
                return;
              } 
              value = picker.value;
            }
            el.format('color', value, 'user');
          },
          'background': (value) => {
            if (value === 'quill-custom-color') {
              if (!isPro()) {
                toast('Custom colors are only available to Pro users.', {
                  duration: 8000,
                  intent: 'danger',
                  upgrade: 'Upgrade to get them.'
                });
                return;
              }

              let picker = /** @type {HTMLInputElement} */ (document.getElementById('quill-custom-color-picker-background'));
              picker.click();

              if (!picker.getAttribute('data-used')) {
                return;
              } 
              value = picker.value;
            }
            el.format('background', value, 'user');
          }
        }
      };
    } else {
      editorConfig.quillModules.toolbar = null;
      editorConfig.registry = getPlainRegistry();
    }


    el = quill.current = createQuillEditor(editorConfig);


    if (!props.isAI) {
    // we don't want anything in the toolbar to be tabable
      [...quillToolbarRef.current.querySelectorAll('.ql-picker-label')].forEach(el => el.setAttribute('tabIndex', '-1'));
    }


    if (!props.editable) {
      el.disable();
    }
    const highlighterObj = highlighter.current = getHighlighter(el);
    highlighterObj.contextCallback = contextChanged;
    snippetEditorContext.setAddons(activeAddons || {});
    highlighterObj.addons = activeAddons || {};
    highlighterObj.isCollapsedEnabled = isCollapsedEnabled;
    highlighterObj.insertFn = insert;
    highlighterObj.showTypedMenu = (targetEl, name) => {
      setShowTypedMenu({ targetEl, name });
    };

    el.root.addEventListener('click', (ev) => {
      let image = Parchment.Registry.find(/** @type {HTMLElement} */ (ev.target), true);
      
      contextChanged({
        forceCollapseState: -1
      });
      if (image instanceof ImageBlot) {
        el.setSelection(image.offset(el.scroll), 1, 'user');
      }
    });

    el.root.addEventListener('dblclick', (ev) => {
      let image = Parchment.Registry.find(/** @type {HTMLElement} */ (ev.target), true);

      if (image instanceof ImageBlot) {
        el.setSelection(image.offset(el.scroll), 1, 'user');
        let imageData = image.formats();

        getData(Object.assign({}, imageConfig, {
          defaults: Object.assign({
            aspectRatio: imageData.naturalHeight / imageData.naturalWidth
          }, imageData),
          info: `Source image size is ${imageData.naturalWidth} x ${imageData.naturalHeight} px.`,
          footer: image.domNode.closest('td') ? 'Note: Images in tables cannot exceed its cell width. They will be resized.' : ''
        })).then((data) => {
          // @ts-ignore
          data.forEach(item => image.format(item.id, item.value));
        });
      }
    });

    el.on('selection-change', (range) => {
      if (range) {
        editorHasBeenFocused.current = true;
      }
    });

    el.on('text-change', (_delta, _oldDelta, source) => {
      if (source === 'api') {
        // pass
      } else if (source === 'user' && !highlighter.current.isUpdatingQuill && !imagesUploadList.current.length) {
        onChange(/** @type {DeltaType} */ (el.getContents()));
      }
    });

    el.clipboard.addMatcher('img', (node, delta) => {
      let imageNode = /** @type {HTMLImageElement} */ (node);
      if (!isPro()) {
        warnImagesPro();
        return new Delta();
      } else {
        logFn({ category: 'Image', action: 'Clipboard paste self' });
        if (imageNode.src.startsWith(IMAGE_URL_PREFIX)) {
          return convertImageToInsertImage(delta);
        } else {
          uploadPastedImage(imageNode.src);
          return convertImageToInsertImage(delta, true);
        }
      }
    });

    if (props.value) {
      highlighter.current.highlight(true, /** @type {any} */ (props.value.delta));
      valueIds.current.push(props.value.id);
      editorHasBeenFocused.current = false;
      setTimeout(() => updateCharacterCount(props.value.delta), 200);
      // @ts-ignore
      el.history.clear();
    }
  });

  

  function removeFromImageUploadList(url) {
    imagesUploadList.current.splice(imagesUploadList.current.indexOf(url), 1);
  }

  async function uploadPastedImage(url) {
    if (imagesUploadList.current.includes(url)) {
      return;
    }

    imagesUploadList.current.push(url);
    warnOnExitForImages('Some images are still uploading, are you sure you want to exit?');

    let uploadUrl;
    try {
      const downloadImageResponse = await fetch(url);
      const contentType = downloadImageResponse.headers.get('content-type');
      const extension = imageMimeTypeToExtension(contentType);
      if (SUPPORTED_IMAGE_EXTENSIONS.includes(extension)) {
        const uploadResult = await uploadImage({
          name: 'file.' + extension,
          type: 's',
          image: arrayBufferToString(await downloadImageResponse.arrayBuffer()),
        });
        uploadUrl = uploadResult.data.url;
      } else {
        toast(`A pasted image has an unsupported extension: "${extension}".`, {
          duration: 5000,
          intent: 'danger'
        });
      }
    } catch (err) {
      console.error(err);
      toast('An error occurred uploading a pasted image. Please download it to your device, and then ' +
          'upload it via the editor toolbar.', {
        duration: 5000,
        intent: 'danger'
      });
    }

    removeFromImageUploadList(url);
    // replacing or removing image blots will cause auto-saving new changes
    if (uploadUrl) {
      replaceImageBlots(url, uploadUrl);
    } else {
      removeImageBlots(url);
    }

    cancelWarningOnCloseForImages();
  }

  function replaceImageBlots(oldUrl, newUrl) {
    const elements = document.getElementsByClassName(ImageBlot.pastedClassName(oldUrl));
    // WORKAROUND to keep the order of elements fixed, as further dom changes will alter their order in the returned list
    const elementsFixed = [];
    for (let i = 0; i < elements.length; i++) {
      elementsFixed.push(elements.item(i));
    }

    for (const element of elementsFixed) {
      const blot = /** @type {ImageBlot} */ (Parchment.Registry.find(element, true));
      blot.uploadDone(newUrl);
    }
  }

  function removeImageBlots(oldUrl) {
    const elements = document.getElementsByClassName(ImageBlot.pastedClassName(oldUrl));
    while (elements.length) {
      const element = elements.item(0);
      const blot = Parchment.Registry.find(element, true);
      const index = blot.offset(quill.current.scroll);
      quill.current.removeFormat(index, 1, 'user');
    }
  }

  function warnImagesPro() {
    toast('Images in snippets are only available to Pro users.', {
      duration: 8000,
      intent: 'danger',
      upgrade: 'Upgrade to get them.'
    });
  }

  function showChipsNotification() {
    let message = <>
      We've improved the modern snippet editing! We've enabled it for you, so you can check it out. You can switch back in the <RouterLink to="/configure/options" style={{ color: 'inherit', textDecoration: 'underline' }}>settings page</RouterLink>.
    </>;
    toast(message, {
      intent: 'info',
      duration: 10000
    });
  }

  function formatText(format, value) {

    const betterTable = getBetterTable(quill.current);
    if (betterTable) {
      betterTable.format(format, value);
    } else {
      quill.current.format(format, target, 'user');
    }
  }

  function isInTable() {
    const el = quill.current;
    const betterTable = getBetterTable(el);
    const [thisLeaf] = el.getLine(el.getSelection()?.index);

    
    let isInside = thisLeaf && thisLeaf.statics.blotName === 'tableCellLine';
    const selectedTds = /** @type {object[]=} */ (betterTable?.tableSelection?.selectedTds);
    // If a user selects a text in a cell
    const selection =  el.getSelection(selectedTds?.length <= 1);
    let selectionLength = selection?.length || 0;
    return {
      isInside,
      selectionLength,
      selectedTds
    };
  }

  function editor() {
    /**
     * @type {React.MouseEventHandler<HTMLDivElement>}
     */
    const onToolbarClick = (evt) => {
      const inTable = isInTable();
      if (
        (!inTable.isInside)
        && !inTable.selectedTds?.length
      ) {
        return;
      }
      const evtTarget = /** @type {HTMLElement} */ (evt.target);
      let inputSource = evtTarget.closest('button,.ql-picker');
      if (!inputSource || !inputSource.classList?.length) {
        return;
      }
      let input = inputSource;
      if (input.tagName.toLowerCase() !== 'button') {
        input = input.nextElementSibling;
      }
      const format = Array.prototype.find
        .call(input.classList, c => c.startsWith('ql-'))
        .slice('ql-'.length);
      const closePicker = () => {
        evt.stopPropagation();
        if (input.tagName === 'SELECT') {
          inputSource.classList.toggle('ql-expanded'); // Toggle aria-expanded and aria-hidden to make the picker accessible
          const label = inputSource.querySelector('.ql-picker-label');
          label.removeAttribute('aria-expanded');
          label.removeAttribute('aria-hidden');
        }
      };
      if ([
        'image',
        'link'
      ].includes(format)) {
        return;
      }
      
      if (!QuillBetterTable.WHITELISTED_FORMATS.includes(format)) {

        toast('Table does not support this format', {
          intent: 'danger'
        });
        closePicker();
        return;
      }

      if (QuillBetterTable.FORMAT_OVERRIDES.includes(format) && inTable.selectionLength > 1) {
        closePicker();
        return;
      }
      if (inTable.selectionLength >= 1) {
        return;
      }
      
      let value;
      if (input.tagName === 'SELECT') {
        const pickerTime = evtTarget.closest('.ql-picker-item');
        if (!pickerTime) {
          return;
        }
        if (pickerTime?.hasAttribute('data-value')) {
          value = pickerTime.getAttribute('data-value');
          if (value === 'quill-custom-color') {
            return;
          }
        } else {
          value = '';
        }
      } else {
        if (input.classList.contains('ql-active')) {
          value = false;
        } else {
          // @ts-ignore
          value = input.value || !input.hasAttribute('value');
        }
      }
      formatText(format, value);
      closePicker();
    };
    return (<div
      ref={quillContainerRef}
      /**
       * Google auto-translate clears out the snippet editor and deletes all
       * text. This disables that.
       */
      className={'notranslate' + (props.editable ? '' : ' read-only')}
      id="quill-container"
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: 0,
        overflow: 'hidden'
      }}
    >
      {props.isAI ? null : <div ref={quillToolbarRef} style={{ display: props.editable ? 'block' : 'none' }} onClickCapture={onToolbarClick}>
        <span className="ql-formats">
          <button className="ql-bold" title="Bold" tabIndex={-1}></button>
          <button className="ql-italic" title="Italic" tabIndex={-1}></button>
          <button className="ql-underline" title="Underline" tabIndex={-1}></button>
          <button className="ql-strike" title="Strikethrough" tabIndex={-1}></button>
        </span>

        <span className="ql-formats">
          <button className="ql-link" title="Insert link" tabIndex={-1}></button>
          <button className="ql-image" title="Insert image" tabIndex={-1}></button>
        </span>

        <span className="ql-formats">
          <select className="ql-color" title="Text color" tabIndex={-1}>
            {defaultColors()}
            <option value="quill-custom-color" />
          </select>
          <select className="ql-background" title="Background color" tabIndex={-1}>
            {defaultColors()}
            <option value="quill-custom-color" />
          </select>
        </span>
        <input
          type="color"
          style={{
            visibility: 'hidden',
            width: 0,
            height: 0,
            margin: 0,
            padding: 0,
            borderWidth: 0
          }}
          id="quill-custom-color-picker-color"
          onInput={e => /** @type {HTMLInputElement} */ (e.target).setAttribute('data-used', 'yes')}
          onChange={(e) => formatText('color', e.target.value)}
          tabIndex={-1}
        />
        <input
          type="color"
          style={{
            visibility: 'hidden',
            width: 0,
            height: 0,
            margin: 0,
            padding: 0,
            borderWidth: 0
          }}
          id="quill-custom-color-picker-background"
          onInput={e => /** @type {HTMLInputElement} */ (e.target).setAttribute('data-used', 'yes')}
          onChange={(e) => formatText('background', e.target.value)}
          tabIndex={-1}
        />

        <span className="ql-formats">
          <select className="ql-align" title="Alignment" tabIndex={-1}></select>
          <button className="ql-direction" value="rtl" title="Right to left" tabIndex={-1}></button>
        </span>

        <span className="ql-formats">
          <select className="ql-font" title="Font" tabIndex={-1}>
            <option data-default-selected="true">Default</option>
            <option value="list-break"></option>
            {fonts.map(x => <option key={'font-' + x.class} value={x.class}>{x.name}</option>)}
          </select>
        </span>

        <span className="ql-formats">
          <select className="ql-size" title="Font size" tabIndex={-1}>

            <option value="small">Small</option>
            <option data-default-selected="true"></option>
            <option value="large">Large</option>
            <option value="huge">Larger</option>

            <option value="list-break"></option>

            {fontSizes.map(x => <option key={'font-' + x } value={`${x}pt`}>{x}</option>)}

          </select>
        </span>

        <span className="ql-formats">
          <button className="ql-list" value="ordered" title="Numbered list" tabIndex={-1}></button>
          <button className="ql-list" value="bullet" title="Bullet point list" tabIndex={-1}></button>
        </span>

        <span className="ql-formats">
          <button className="ql-script" value="sub" title="Subscript" tabIndex={-1}></button>
          <button className="ql-script" value="super" title="Superscript" tabIndex={-1}></button>
        </span>

        <span className="ql-formats">
          <button className="ql-clean" title="Remove formatting" tabIndex={-1}></button>
        </span>
        <span className="ql-formats">
          <button value="" title="Add table" tabIndex={-1} onClick={(evt) => {
            setGridSelectorAnchor(evt.currentTarget.closest('.ql-toolbar'));
          }}>
            <GridOnIcon />
          </button>
        </span>
        <span className="ql-formats">
          <button value="" title="Add Emoji" tabIndex={-1} onClick={(evt) => {
            setEmojiSelectorAnchor(evt.currentTarget);
          }}>
            <AddReactionOutlinedIcon />
          </button>
        </span>
        <span className="ql-formats">
          <button data-test-id="ai-write" title="Use Blaze AI to write a snippet" tabIndex={-1} onClick={(evt) => {
            const allContents = quill.current.getContents();
            const currentSelection = quill.current.getSelection();
            const selectedContents = currentSelection ? quill.current.getContents(currentSelection.index, currentSelection.length) : null;

            setOpenedAIWriteOnce(true);
            setShowAutoWrite({
              content: convertDeltaToString(allContents),
              selection: selectedContents ? convertDeltaToString(selectedContents) : null,
            });
            aiWriteAnchor.current = evt.currentTarget;
          }} className={isAIGenerating ? 'pulsing-text' : ''} style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', width: 'unset' }}><AIIcon style={{ zoom: .7, opacity: .9, marginRight: 6, display: 'inline-block' }}/>AI Write</button>
        </span>
        
      </div>}
      <div
        className="quill-wrapper-container"
        ref={quillRef}
        style={{
          flex: 1,
          // So it grows from here
          height: 0
        }}
        onKeyDown={() => {
          if (clearSuggestionFn.current) {
            clearSuggestionFn.current();
            clearSuggestionFn.current = null;
          }
        }}
      />
    </div>);
  }

  function onStartAIGeneration() {
    setIsAIGenerating(true);
  }

  function onStopAIGeneration() {
    setIsAIGenerating(false);
  }

  function autoSaveNotification() {
    if (props.showAutoSaveNotice) {
      return <div key="auto-save" className="auto-save-bar">
        <T variant="body2" style={{
          paddingLeft: 16,
          paddingRight: 16
        }}>
          <svg aria-hidden="true" focusable="false" style={{ verticalAlign: 'middle', width: 14, marginRight: 8 }} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160h136v328c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0h200v136c0 13.2 10.8 24 24 24zm65.296 109.732l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L162.12 346.45l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068L70.772 328.2c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z"></path></svg>
          <span style={{
            fontWeight: 'bold',
            verticalAlign: 'middle'
          }}>AutoSaved: All changes to snippets and folders are saved automatically. <span style={{
              paddingLeft: 8,
              fontWeight: 'normal'
            }}>We won't show this again</span>
          </span>
        </T>
      </div>;
    }
  }

  function characterCounter() {
    if (props.editable) {
      let limit = limitations.MAX_SNIPPET_SIZE;
      if (limit) {
        let chars = characterCount;
        let formatter = format(',');
        if (chars > limit - 400) {
          let msg = `Used ${formatter(chars)}/${formatter(limit)} characters.${chars > limit ? ' Changes will not be saved.' : ''}${!isPro() ? ' Unlock Text Blaze Pro for more.' : ''}`;
          return <div key="character-count" className={'character-counter' + (chars > limit ? ' character-excess' : (chars > limit - 100 ? ' character-warning' : ''))}>
            {msg}
          </div>;
        }
      }
    }
  }

  /**
   * @param {DeltaType | import('quill/core').Delta} delta
   */
  function updateCharacterCount(delta) {
    if (window['testing-no-highlighter']) {
      // don't want to update state when testing
      // which will trigger the need of act()
      return;
    }

    if (!isMounted.current) {
      return;
    }

    let txtLen = 0;
    for (let op of delta.ops) {
      if (op.insert && typeof op.insert === 'string') {
        txtLen += op.insert.length;
      }
    }
    if (txtLen !== characterCount) {
      setCharacterCount(txtLen);
    }
    return txtLen;
  }

  function updateSnippetClassification() {
    if (!props.snippetId || !props.onChange) {
      // If snippet is draft or not within a
      // <Snippet> component, no need to classify
      return;
    }
    const snippetContent = convertDeltaToString(quill.current.getContents()).trim();
    if (!snippetContent) {
      // Do not classify empty snippets
      return;
    }
    makeClassificationRequest({ messages: [{ content: snippetContent, role: 'user', }], domainData: { template: '', selectedContent: '', }, }).then(result => {
      if ('action' in result) {
        props.onChange('ai_action_user', result.action);
      }
      // Silently ignore errors, user might be offline for example, or OpenAI server may be down
    });
  }
  const updateSnippetClassificationDebounced = debounce(updateSnippetClassification, 5000);
  
  /**
   * @param {DeltaType} delta
   */
  function onChange(delta) {
    let expandedContents = expandDeltaContents(delta);
    let len = updateCharacterCount(expandedContents);
    
    if (!limitations.MAX_SNIPPET_SIZE || len <= limitations.MAX_SNIPPET_SIZE) {
      let id = Date.now() + '-' + Math.random();
      valueIds.current.push(id);
      props.onChange?.('delta', expandedContents, id);
    }

    if (props.isAI) {
      updateSnippetClassificationDebounced();
    }
  }

  async function onVariableNameChange(variableName, newName) {
    const env = getHighlightEnvironment(activeAddons);
    const delta = expandDeltaContents(/** @type {DeltaType} */ (quill.current.getContents()));
    const nodes = await tokenize(delta, env.config.mode, env);
    // save the info from context we need before we update Quill to avoid losing the data
    // Store the rangeIndex in the context, because by the time this runs, Quill will be unfocused and the range lost
    const rangeIndex = context?.rangeIndex;
    const collapseSelectedStartPosition = context?.collapsedSelected?.data?.meta?.startPosition;
    const nodesData = context?.nodesData;

    const realRangeIndex = getRealRangeIndexFromNodes(rangeIndex, nodesData, nodes);

    const replaceOrder = await getVariableReplaceOrderFromDelta(
      delta,
      variableName,
      realRangeIndex,
    );

    const updatedDelta = await changeVariableNameInDelta(delta, replaceOrder, variableName, newName);

    // update contents and let highlighter collapse all text into chips
    const contentLengthBeforeUpdate = quill.current.getLength();
    quill.current.setContents(/** @type {any} */(updatedDelta), 'user');
    const newDelta = await highlighter.current.highlight(true, null, 'user');

    // clear the rangeIndex since the position we have stored could've changed
    contextChanged({
      rangeIndex: undefined,
    });

    const blot = getCollapsedCommandByIndex(quill.current, collapseSelectedStartPosition);

    if (!newDelta || !blot) {
      return;
    }

    /*
     * If they're not the same we assume everything could've changed location (like when collapsing commands)
     * and trying to find it can lead to selecting the wrong thing
     */
    if (quill.current.getLength() !== contentLengthBeforeUpdate) {
      return;
    }


    /**
     * When the delta changes, the DOM nodes are different, so we need to find the equivalent node
     * and re-select it.
     */
    if (blot?.domNode && blot?.blazeData) {
      highlighter.current.updateTokenHighlight(collapseSelectedStartPosition);

      contextChanged({
        forceCollapseState: 2,
        collapsedSelected: {
          data: blot.blazeData,
          rootData: blot.blazeData,
          node: /** @type {HTMLElement} */ (blot.domNode)
        }
      });
    }
  }

  async function getVariableCountInSnippet(variableName) {
    const env = getHighlightEnvironment(activeAddons);
    const delta = expandDeltaContents(/** @type {DeltaType} */ (quill.current.getContents()));
    const nodes = await tokenize(delta, env.config.mode, env);

    const rangeIndex = context?.rangeIndex;
    const nodesData = context?.nodesData;

    const realRangeIndex = getRealRangeIndexFromNodes(rangeIndex, nodesData, nodes);
    return getVariableCountInDelta(delta, variableName, realRangeIndex);
  }

  function getDelta() {
    return props.value.delta;
  }
  
  /**
   * @type {import('./highlighter').default['insertFn']}
   */
  async function insert(txt, autoSelect = false, useTableLogic = false) {
    const el = quill.current;

    let hasBeenFocused = editorHasBeenFocused.current; // note the getSelection below call changes this
    let range = el.getSelection(true);
    if (range === null || (!hasBeenFocused && range.index === 0 && range.length === 0)) {
      // When the snippet is initially viewed but the editor hasn't
      // been focused, the selection will be at the start of the doc.
      // But we want the insert to happen at the end.
      range = { index: el.getLength() - 1, length: 0 };
    }

    let loc = range.index;

    const inTable = isInTable();
    // if its a table and has selected cells and for commands to spread across the cells
    // For context menu repeat/if, selectionLength would be null as it lost the focus.
    if (useTableLogic && inTable.selectedTds?.length) {
      loc = quill.current.getIndex(inTable.selectedTds[0].parent.children.head);
    // if its a table, then lets insert into the first cell.
    } else if (!inTable.selectionLength && inTable.selectedTds?.length > 1) {
      loc = quill.current.getIndex(inTable.selectedTds[0]);
    } else {
      el.deleteText(range.index, range.length, 'user');
    }
    
    if (!isCollapsedEnabled && autoSelect) {
      el.insertText(loc, txt, 'user');
      el.setSelection(loc, txt.length);
      el.focus();
    } else if (!isCollapsedEnabled) {
      el.insertText(loc, txt, 'user');
      el.setSelection(loc + txt.length, 0);
      el.focus();
    } else {
      let lengthToSelect = txt.length;
      const env = new Environment(null, {
        stage: 'tokenization',
        addons: activeAddons
      });
      const res = await tokenize({
        ops: [{
          insert: txt
        }]
      }, 'text', env);
      await tokenizeAttributes(res, env);
      let txtPosition = 0;
      let rootTokenStartPosition = loc;
      let firstCommand;
      for (let partIndex = 0; partIndex < res.length; partIndex++) {
        const part = res[partIndex];
        lengthToSelect -= part.endPosition - part.startPosition - 1;
        if (part.startPosition > txtPosition) {
          let subText = txt.slice(txtPosition, part.startPosition);
          el.insertText(loc, subText, 'user');
          loc += subText.length;
        }
        if (part.type === 'error') {
          let subText = txt.slice(part.startPosition, part.endPosition);
          el.insertText(loc, subText, 'user');
          txtPosition = part.endPosition;
          loc += subText.length;

          // Add an error to log in sentry
          console.log('command', subText);
          console.log('fulltext', txt);
          console.error('Error when inserting commands');
          continue;
        }
        let collapsedData = getCollapsedData(Math.random().toString(32), part);
        if (!firstCommand) {
          firstCommand = {
            loc,
            collapsedData
          };
        }
        collapsedData.meta.rootTokenStartPosition = rootTokenStartPosition;

        if (part === res[res.length - 1] && useTableLogic) {
          let cell = inTable.selectedTds[inTable.selectedTds.length - 1].parent.children.tail;
          let line = cell.children.tail;
          let qlIndex = el.getIndex(line);
          loc = qlIndex + line.cache.length - 1;
        }
        el.insertEmbed(loc, 'collapsedCommand', collapsedData, 'user');
        txtPosition = part.endPosition;
        loc++;
      }
      if (txtPosition < txt.length) {
        el.insertText(loc, txt.slice(txtPosition, txt.length), 'user');
        loc += txt.slice(txtPosition, txt.length).length;
      }

      // If its auto select, we can select the command as it would corrupt the snippet on edit.
      if (autoSelect) {
        el.setSelection(range.index, lengthToSelect);
        el.focus();
      } else {
        el.setSelection(loc, 0);
        el.focus();
        const commandSpec = firstCommand?.collapsedData?.value?.spec;
        let shouldSelect = !!commandSpec?.positionalDef;
        if (!shouldSelect && commandSpec) {
          for (const key in commandSpec.named) {
            const element = commandSpec.named[key];
            if (element.priority >= 0) {
              shouldSelect = true;
              break;
            }
          }
        }
        if (shouldSelect) {
          let leaf;
          let elementsNavigated = 1;
          // if its a table logic, then it should be from the first cell. no need to navigate.
          if (useTableLogic) {
            leaf = inTable.selectedTds[0].parent.children.head.children.head.children.head;
            elementsNavigated = res.length;
          } else {
            leaf = el.getLeaf(loc)[0];
            const prev = () => {
              return leaf.prev || leaf.parent.prev?.children?.tail;
            };
            while (elementsNavigated < res.length && (leaf = prev())) {
              while (prev() && leaf.statics.blotName !== 'collapsedCommand') {
                leaf = prev();
              }
              elementsNavigated++;
            }
          }
          if (!leaf) {
            console.error('Unable to navigate to blot: ', txt);
          } else if (elementsNavigated === res.length) {
            contextChanged({
              forceCollapseState: 2,
              collapsedSelected: {
                data: firstCommand.collapsedData,
                rootData: firstCommand.collapsedData,
                node: /** @type {HTMLElement} */ (leaf.domNode)
              }
            });
          }
        }
      }
    }
    if (props.showCommands && props.setShowCommands) {
      props.setShowCommands(false);
    }
  }

  /***
   * Inserts a table with data and convert commands into chips. 
   * Note: The data is assumed to have equal columns across rows. Any discrepency would break insertion.
   * @param {(string|{ insert: string, attributes: import('quill/core').AttributeMap })[][]} data
   */
  async function insertTable(data) {
    const rows = data.length,
      cols = data[0].length,
      el = quill.current;
    const editorWidth = el.root.clientWidth;
    const colWidth = Math.floor((editorWidth - 50) / cols);
    const columnConfig = new Array(cols).fill({
      width: Math.max(colWidth, 100)
    });
    if (!isCollapsedEnabled) {
      return getBetterTable(el).insertTableWithData(data, columnConfig);
    }
    let env = new Environment(null, {
      stage: 'tokenization',
      addons: activeAddons
    });
    let range = el.getSelection(true);
    if (range === null || (!editorHasBeenFocused.current && range.index === 0 && range.length === 0)) {
      // When the snippet is initially viewed but the editor hasn't
      // been focused, the selection will be at the start of the doc.
      // But we want the insert to happen at the end.
      range = { index: el.getLength() - 1, length: 0 };
    }

    let loc = range.index;
    let normalizedData = data.map(cells =>
      cells.map(cell => {
        return typeof cell === 'string' ?
          { insert: cell, attributes: {} }
          : cell;
      })
    );

    const txt = normalizedData.map(cells =>
      cells.map(cell => cell.insert).join('')
    ).join('');
    const parts = await tokenize({
      ops: [{
        insert:  txt
      }]
    }, 'text', env);

    let txtPosition = 0;
    const newData = [];
    let rowCursor = 0,
      cellCursor = 0,
      cellTxtPosition = 0;
    let newCells = [];
    let cellToAdd = [];
    newCells.push(cellToAdd);
    newData.push(newCells);

    /**
     * Moves all the cursors
     */
    const moveCursors = () => {
      // More text is still inside cell
      if (cellTxtPosition < normalizedData[rowCursor][cellCursor].insert.length) {
        return;
      }

      cellTxtPosition = 0;
      cellCursor++;
      cellToAdd = [];
      if (cellCursor === cols && rowCursor + 1 < rows) {
        rowCursor++;
        cellCursor = 0;
        
        newCells = [];
        newCells.push(cellToAdd);
        newData.push(newCells);
      } else if (cellCursor < cols) {
        newCells.push(cellToAdd);
      }
    };

    /**
     * Add all the remaining text into table data
     * @param {number} upto 
     */
    const addRemainingText = (upto) => {
      while (upto > txtPosition) {
        let textToAdd = normalizedData[rowCursor][cellCursor]
          .insert;
        textToAdd = textToAdd.slice(cellTxtPosition, textToAdd.length);
        if (txtPosition + textToAdd.length > upto) {
          textToAdd = textToAdd.slice(0, upto - txtPosition);
        }
        cellToAdd.push({
          insert: textToAdd,
          attributes: normalizedData[rowCursor][cellCursor].attributes
        });

        cellTxtPosition += textToAdd.length;
        moveCursors();
        txtPosition += textToAdd.length;
        loc += textToAdd.length;
      }
    };

    for (let index = 0; index < parts.length; index++) {
      const part = parts[index];
      let rootTokenStartPosition = loc;
      addRemainingText(part.startPosition);
      
      const collapsedData = getCollapsedData(Math.random().toString(32), part);

      collapsedData.meta.rootTokenStartPosition = rootTokenStartPosition;
      cellToAdd.push({
        insert: {
          'collapsedCommand': collapsedData
        },
        attributes: normalizedData[rowCursor][cellCursor].attributes
      });

      //Move cellTxtPosition to the new command
      cellTxtPosition += part.endPosition - txtPosition;

      moveCursors();
      txtPosition = part.endPosition;
      loc++;
    }
    addRemainingText(txt.length);

    getBetterTable(el).insertTableWithData(newData, columnConfig);
  }

  /**
   * @param {EditorContextType} newContext 
   */
  function contextChanged(newContext) {
    if (!isMounted.current) {
      return;
    }
    setSnippetHasCommands(highlighter.current?.context?.nodesData?.length > 0);

    if (newContext && newContext.collapsedSelected) {
      if (!props.showCommands && props.setShowCommands) {
        props.setShowCommands(true);
      }
    }
    const removeCommandSelect = newContext.forceCollapseState === -1;
    if (removeCommandSelect) {
      delete newContext.forceCollapseState;
    }
    setContext((context) => {
      let c = Object.assign({}, context, newContext);
      if (newContext.hasFocus && !newContext.collapsedSelected && !c.forceCollapseState) {
        delete c.collapsedSelected;
      }
      if (removeCommandSelect && typeof c.forceCollapseState !== 'undefined') {
        delete c.collapsedSelected;
      }
      if (c.forceCollapseState === 0) {
        delete c.forceCollapseState;
      }
      if (c.forceCollapseState) {
        c.forceCollapseState--;
      }
      return c;
    });
    if (props.handleNames) {
      props.handleNames(newContext.names);
    }
    if (newContext.types) {
      highlighter.current.types = newContext.types;
    }
    return newContext.types;
  }


  function handleImageUpload(res) {
    if (res) {
      let { url } = res;

      let range = quill.current.getSelection(true);
      if (range.index === 0 && range.length === 0) {
        range = { index: quill.current.getLength() - 1, length: 0 };
      }
      quill.current.insertText(range.index, '\n', 'user');
      quill.current.insertEmbed(range.index + 1, 'insert-image', { url }, 'user');
      quill.current.setSelection(range.index + 2, 0, 'silent');
    }
  }


  let formNames, target;
  if (context && context.collapsedSelected) {
    formNames = context.names || [];
    if (props.formNames) {
      formNames = [...new Set(formNames.concat(props.formNames))];
    }
    context.names = formNames;
    target = context.collapsedSelected.node;
    if (context.collapsedSelected.attributeName) {
      let parentSelector = 'span[data-attribute-name="' + context.collapsedSelected.attributeName + '"]';

      // We attempt the attribute name first as it's less likely to move when editing
      // If it doesn't exist we look for the attribute value (e.g. for positional settings)
      /** @type {any} */
      let attrTarget = target.querySelector(parentSelector + ' .list-attribute-name, ' + parentSelector + ' .list-attribute-value');
      if (attrTarget) {
        attrTarget._BlazeErrorCallbackFn = target._BlazeErrorCallbackFn;
        target = attrTarget;
      }
    }
  }

  /**
   * @param {string} prompt
   * @param {boolean} pageContext 
   */
  function setAIExample(prompt, pageContext) {
    quill.current.setText(prompt, 'user');
    props.onChange('include_page_context', pageContext);
    props.openScratchPad(prompt);
  }
  

  /**
   * 
   * @param {import('./editor_utilities').CollapsedDataType} newData 
   * @param {import('./editor_utilities').CollapsedDataType} rootData 
   * @param {object=} nameUpdateObj
   * @param {boolean=} ignoreSidebar
   */
  const tokenizeDataWrapper = async (newData, rootData, nameUpdateObj, ignoreSidebar) => {
    let blot = getCollapsedCommandBlot(context.collapsedSelected.node);
    const id = rootData.meta.id;
    const currentCounter = tokenizeCounters[id] = tokenizeCounters[id] + 1 || 0;
    const finalData = await tokenizeData(rootData, activeAddons);
    if (currentCounter !== tokenizeCounters[id] || !isMounted.current) {
      return;
    }
    if (newData.meta.id === finalData.meta.id) {
      newData = finalData;
    } else {
      newData = getAttributeData(finalData, newData.meta.attributesPath);
    }

    // update the data directly without updating the context since we don't want to wait for updates
    if (ignoreSidebar) {
      if (blot) {
        blot.updateData(newData, true);
      }
    } else {
      updateCollapsedData(newData, finalData, nameUpdateObj);
    }
  };

  /**
   * @type {import('./EmbeddedCommand/EmbeddedAttributeSection').onChangeCallback}
   */
  const onSidebarChange = async (newData, nameUpdateObj, ignoreSidebar) => {
    let rootData = newData;
    if (newData.meta.attributesPath) {
      rootData = updateAttributeTree(newData, context.collapsedSelected.node);
    }
    
    // lets call this async. So it would update again once the data is changed
    // unless ignoreSidebar is set, in which case it will update only once.
    tokenizeDataWrapper(newData, rootData, nameUpdateObj, ignoreSidebar);

    if (!ignoreSidebar) {
      updateCollapsedData(newData, rootData, nameUpdateObj);
    }
  };

  /**
   * @param {import('./editor_utilities').CollapsedDataType} newData 
   * @param {import('./editor_utilities').CollapsedDataType} rootData 
   * @param {object=} nameUpdateObj
   */
  const updateCollapsedData = (newData, rootData, nameUpdateObj) => {
    setContext(context => {
      try {
        addBreadcrumb({
          message: 'collapsed replacewith: ' + context.collapsedSelected.data.meta.id
        });
        let blot = getCollapsedCommandBlot(context.collapsedSelected.node);
        if (!blot) {
          return {
            ...context,
            collapsedSelected: null
          };
        }
        blot.updateData(rootData, true);

        let newContext = Object.assign({}, context);

        // To not clearing focus everytime.
        delete newContext.forceCollapseState;
        newContext.collapsedSelected = {
          data: newData,
          rootData: rootData,
          node: newContext.collapsedSelected?.node,//newBlot.domNode,
          attributeName: context.collapsedSelected?.attributeName
        };
        return newContext;
      } catch (err) {
        // log the actual error. Somehow ErrorBoundary does not catch the errors in setState.
        console.log(err);
        // Create a new sentry event. Lets not wait for recursion of setState to fail!
        console.error('Failed to set context while replacing the command.');
        // Unable to update to the right context is a failure. Lets crash the app like before.
        throw err;
      }
    });
  };

  let ChangesRequiredComponent;
  if (import.meta.env.VITE_APP_APP_TYPE === 'PAGE') {
    ChangesRequiredComponent = PageChangesRequired;
  } else {
    ChangesRequiredComponent = SnippetChangesRequired;
  }

  // When user is on /new page, the user cannot try the snippet
  // examples, because the user has not saved the snippet yet
  // (and there is no "Try it out" button on the CreateSnippet.js page) 
  const showAIExamples = props.openScratchPad && ((!props.createdAt) || props.createdAt > Date.now() / 1000 - 60 * 60);

  const writeExamples = [
    {
      prompt: `Summarize this ${isElectronFlag ? 'application' : 'page'} with key bullet points`,
      shortcut: props.shortcut || '/summarize',
      postText: '• Currently scheduled for June 7th...'
    },
    {
      prompt: 'Respond to the email using a friendly and informal tone',
      shortcut: props.shortcut || '/respond',
      postText: 'Hey Jane! Thanks for this email and your work on...'
    },
    {
      prompt: 'Address the customer feedback politely',
      shortcut: props.shortcut || '/feedback',
      postText: 'Thanks for your feedback Jay, we appreciate it...'
    },
  ];

  const polishExamples = [
    {
      prompt: 'Rewrite this message in a friendly tone. Add emojis and greetings.',
      initText: 'I need it ASAP!',
      shortcut: props.shortcut || '/tone',
      postText: 'Hi 😊! Please send this to me as quickly as possible. Thank you!'
    },
    {
      prompt: 'Write a brief paragraph based on these points',
      initText: '- it\'s not working\n- check with John to fix',
      shortcut: props.shortcut || '/bullets',
      postText: 'Unfortunately, it\'s not working right now. Can you check with John? He should be able to fix it. '
    },
    {
      prompt: 'Fix any spelling and grammar mistakes',
      initText: 'how r u doin 2dya?',
      shortcut: props.shortcut || '/gram',
      postText: 'How are you doing today?'
    }
  ];
  const chatExamples = [
    {
      prompt: 'What is the diameter of the sun?',
      shortcut: props.shortcut || '/sun',
      postText: 'The diameter of the sun is 1.4 million kilometers',
    },
    {
      prompt: 'Recipes for a simple breakfast',
      shortcut: props.shortcut || '/food',
      postText: 'Some easy to cook recipes for breakfast are ...'
    },
    {
      prompt: 'Explain superconductors like a five year old',
      shortcut: props.shortcut || '/explain',
      postText: 'Superconductors as explained to young children...'
    }
  ];

  const chosenExamples = props.aiAction === 'chat' ? chatExamples : (props.aiAction === 'write' ? writeExamples : polishExamples);

  return (
    <>
      <ImageUploader
        ref={uploaderRef}
        type="s"
      />
      {showTypedMenu && <TypedMenu
        targetEl={showTypedMenu.targetEl}
        name={escapeFormName(showTypedMenu.name)}
        type={context?.activeTypes?.[showTypedMenu.name]}
        onClose={() => setShowTypedMenu(null)}
        actions={{
          insert: (txt) => {
            insert(txt);
            setShowTypedMenu(null);
          },
          insertTable: (data) => {
            insertTable(data);
            setShowTypedMenu(null);
          }
        }}
      />}
      <ClickAwayListener
        onClickAway={() => setFocusedInEditor(false)}
      >
        <Box
          sx={(focusedInEditor && isXShort) ?
            /** UI for small mobile devices, hide a bunch of other stuff when editing */
            {
              position: 'fixed',
              top: 46,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 1100 + 1, // just above app drawer, below drawer and modal
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              borderLeft: '1px solid #ddd !important'
            }
            /** normal ui */
            : {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              position: 'relative',

              gridColumnStart: 'main-start',
              gridRowStart: 'editor-start',
              gridRowEnd: 'editor-end',
              
              ...props.editorStyle,

            }}
          onClick={() => setFocusedInEditor(true)}
        >
          {
            props.isAI  ?
              <Box sx={{
                textAlign: 'center',
                zIndex: 10
              }}>
              </Box>
              : null}

          <Box
            sx={Object.assign({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }, (focusedInEditor && isXShort) ?
            /** UI for small mobile devices, hide a bunch of other stuff when editing */
              {
                display: 'contents'
              }
            /** normal ui */
              : {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                position: 'relative',
                borderLeft: '1px solid #ddd !important',
                borderRight:  '1px solid #ddd !important',
                borderTop: props.isAI ? '1px solid #ddd !important' : undefined,

                paddingTop: props.isAI ? 3 : 0,

                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                ...props.editorStyle,

              })}>
            {
              props.isAI ? <Box
                sx={{
                  px: 2
                }}
              >
              
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}>
                  <T variant="h6">Type your prompt here</T>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                    <div
                      style={Object.assign({
                        flex: 1,
                        minHeight: 152,
                        display: 'flex',
                        flexDirection: 'column',
                      }, AI_EDITOR_STYLES)}
                    >
                      {editor()}
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 3
                    }}
                  >
                    {props.openScratchPad && <Button
                      variant="contained"
                      size="large"
                      startIcon={props.aiAction === 'polish' ? <AIPolishIcon /> : (props.aiAction === 'chat' ? <AIChatIcon /> : <AIInsertIcon />)}
                      onClick={() => {
                        props.openScratchPad();
                      }}>
                      Try out this prompt
                    </Button>}
                    <div style={{ flex: 1 }}></div>
                    {snippetHasCommands ?
                      <div style={{
                        textAlign: 'right',
                      }}>
                        <T sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                          mr: 2,
                          display: 'inline-block',
                        }} variant="caption" role="button" aria-label="Preview prompt button" onClick={() => {
                          setPromptPreviewVisible(true);
                        }}>Preview this prompt</T>
                      </div> : null
                    }
                    <IconButton
                      onClick={(e) => {
                        setShowAdvancedAISettings(e.target);
                      }}
                      size="large"
                    >
                      <AdvancedAISettingsIcon />
                    </IconButton>
                  </Box>
                </Box>

                {showAdvancedAISettings && <Menu
                  anchorEl={showAdvancedAISettings}
                  open
                  onClose={() => {
                    setShowAdvancedAISettings(null);
                  }}>
                  <Box
                    sx={{
                      maxWidth: '460px',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      sx={{
                        p: 2
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.includePageContext}
                            disabled={!props.editable}
                            onChange={() => {
                              props.onChange('include_page_context', !props.includePageContext);
                            }} />
                        }
                        label="Include page context for better results"
                      />
                      <T
                        variant="caption"
                        color="textSecondary"
                        sx={{
                          mt: 1,
                          display: 'block'
                        }}
                      >Automatically include part of the page text to give the AI context and improve results. Page text will be sent to Blaze and OpenAI but will not be used in model training.{isElectronFlag || <> You can always include a specific part of the page in your prompt using the <a href="https://blaze.today/commands/site" target="_blank" rel="noreferrer noopener">{'{site}'} command</a>.</>}</T>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        p: 2
                      }}
                    >
                      <T variant="subtitle2" sx={{
                        mb: 1
                      }}>Desired action</T>
                      <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={props.aiAction}
                        exclusive
                        onChange={(_e, val) => {
                          // TODO: remove on extension update
                          props.onChange('polish_mode', val === 'polish');
                          props.onChange('ai_action_user', val);
                        }}
                        disabled={!props.editable}
                      >
                        <ToggleButton value="write"
                          sx={{
                            background: props.aiAction === 'write' ? 'linear-gradient(to right, #e6f0ff, #e8fffa)' : 'white !important'
                          }}
                        ><AIInsertIcon sx={{
                            mr: 2,
                          }} /> Write with AI </ToggleButton>

                        <ToggleButton value="polish"
                          sx={{
                            background: props.aiAction === 'polish' ? 'linear-gradient(to right, #e6f0ff, #e8fffa)' : 'white !important'
                          }}><AIPolishIcon sx={{
                            mr: 2
                          }} /> Polish with AI</ToggleButton>

                        <ToggleButton value="chat"
                          sx={{
                            background: props.aiAction === 'chat' ? 'linear-gradient(to right, #e6f0ff, #e8fffa)' : 'white !important'
                          }}><AIChatIcon sx={{
                            mr: 2
                          }} /> Chat with AI</ToggleButton>
                      </ToggleButtonGroup>

                      <T variant="caption" color="textSecondary" sx={{
                        mt: 1,
                        display: 'block'
                      }}>The results will be tailored towards the action. <b>Chat</b>: Ask questions or discuss something. <b>Polish</b>: Improve, translate, or edit text. <b>Write</b>: compose emails or other content.</T>
                    </Box>
                  </Box>
                </Menu>}

                {showAIExamples && <AIExamples quill={quill.current} examples={chosenExamples} onExampleSelect={setAIExample} logFn={logFn} />}

                <div>
                  {!isAiBlaze && <div style={{
                    display: 'flex',
                    marginBottom: '12px',
                    alignItems: 'center'
                  }}>
                    <div style={{
                      flex: 1
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                        <BetaChip />
                      </Box>
                    </div>

                    <ProChip />
                  </div>}
              
                </div>

                {promptPreviewVisible &&  <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => {
                  setPromptPreviewVisible(false);
                }}>
                  <Dialog
                    open
                    onClose={() => {
                      setPromptPreviewVisible(false);
                    }}
                    sx={{
                      // Set to 100 so that RemoteBottomStatusBar
                      // can show over the dialog box
                      zIndex: 100,
                    }}>
                    <SnippetPreviewPanel
                      snippet={{
                        group_id: props.groupId,
                        shortcut: props.shortcut,
                        options: {
                          quick_entry: props.quickentry,
                        },
                        id: props.snippetId,
                      }}
                      style={{
                        minWidth: '500px',
                      }}
                      hideShortcut
                      hideProMessage
                      snippetId={props.snippetId}
                      delta={props.value.delta}
                      connected={connectedSettings}
                      onRemoteStatusUpdate={(items) => {
                        setRemoteItems(items);
                      }}
                    />
                    <Box sx={{
                      position: 'sticky',
                      bottom: '0px',
                      borderTop: '0.5px dashed lightgrey;',
                      textAlign: 'center',
                      background: 'white',
                      boxShadow: '0 -5px 5px -5px lightgrey',
                      pt: 3,
                      pb: 4,
                    }}>
                      <Button variant="contained" onClick={() => {
                        setPromptPreviewVisible(false);
                        props.openScratchPad?.();
                      }}>Try this prompt</Button>
                      <RemoteBottomStatusBar items={remoteItems} containerStyle={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        overflow: 'initial',
                      }} />
                    </Box>
                  </Dialog>
                </ClickAwayListener>}
            
              </Box>
                : editor()
            }


            {characterCounter() || autoSaveNotification()}
            {props.draftComponent}
            {props.connectedEditingBlocked && <Alert severity="info">This snippet is part of a Connected Folder. You can only edit shared Connected Folder snippets within the same Text Blaze Business organization</Alert>}
            {props.editable ? (!!context?.token?.info.error ? <CommandError context={context} commandErrorBottom={props.commandErrorBottom || 0} /> : <ChangesRequiredComponent
              snippetId={props.snippetId}
              groupId={props.groupId}
              owner={props.owner}
            />) : !props.connectedEditingBlocked && <ReadonlyNotice />}
          </Box>
        </Box>
      </ClickAwayListener>
      <div style={{
        gridColumnStart: 'sidebar-start',
        gridRowStart: 'editor-start',
        gridRowEnd: 'editor-end',
        position: 'relative',
        overflowY: 'hidden'
      }}>
        {(!props.hidden && !props.isPage) /** If not focused don't show button bar so the ChangeHistory popup doesn't show */ && 
          <SideBar
            readonly={!props.editable}
            snippetId={props.snippetId}
            groupId={props.groupId}
            preview={props.preview}
            xSharingDisabled={props.xSharingDisabled || props.isAddon}
            userAddonsEnabled={props.userAddonsEnabled}
            isAssociatedToUs={props.isAssociatedToUs}
            insert={insert}
            insertTable={insertTable}
            dialog={dialog}
            getDelta={getDelta}
            quickentry={props.quickentry}
            isAI={props.isAI}
            onChange={props.onChange}
            onVariableNameChange={onVariableNameChange}
            addons={activeAddons}
            isEditingToken={!!(context && context.token)}
            isEditingAttribute={!!(context && context.editingAttribute)}
            isPage={props.isPage}
            isAddon={props.isAddon}
            showDynamicBadge={showDynamicBadge}
            showAddonHighlight={showAddonHighlight}
            context={context || {}}
            formNames={props.formNames}
            showCommands={props.showCommands}
            setShowCommands={async (value) => {
              if (value === true) {
                props.setShowCommands(true);
              }
              const beforeCloseResponse = beforeEmbeddedCommandClose(embeddedCommandError.current);
              if (!beforeCloseResponse) {
                props.setShowCommands(value);
                return;
              }
              beforeCloseResponse
                .then(() => props.setShowCommands(value))
                .catch(() => null);
            }}
            override={!!context && !!context.collapsedSelected && document.body.contains(context.collapsedSelected.node) ? <EmbeddedCommandContents
              sidebar
              readonly={!props.editable}
              key={'EMBED_' + context.collapsedSelected.data.meta.id}
              data={context.collapsedSelected.data}
              rootData={context.collapsedSelected.rootData}
              groupId={props.groupId}
              targetAttributeName={context.collapsedSelected.attributeName}
              equationContext={{
                activeTypes: context.activeTypes
              }}
              target={target}
              insert={insert}
              onBack={(path) => {
                setContext((context) => {
                  let domNode = context.collapsedSelected.node;
                  let blot = getCollapsedCommandBlot(domNode);
                  let rootData = blot.blazeData;
                  let currentData = context.collapsedSelected.data;
                  const newData = getAttributeData(rootData, path);

                  const currentChip = /** @type {HTMLElement} */ (domNode.querySelector(`.small-chip[data-token-id="${currentData.meta.id}"]`));
                  /**
                   * Should be command like DB.
                   * Wouldn't have highlight on child command.
                   */
                  if (currentChip) {                    
                    delete currentChip.dataset.highlighted;
                  }
                  if (newData.meta.attributesPath?.length) {
                    const nestedChip = /** @type {HTMLElement} */ (domNode.querySelector(`.small-chip[data-token-id="${newData.meta.id}"]`));
                    if (nestedChip) {
                      nestedChip.dataset.highlighted = 'true';
                    }
                  } else {
                    domNode.dataset.highlighted = 'true';
                  }
                  let newContext = {
                    ...context
                  };

                  // To not clearing focus everytime.
                  delete newContext.forceCollapseState;
                  newContext.collapsedSelected = {
                    data: newData,
                    node: context.collapsedSelected.node,
                    rootData: context.collapsedSelected.rootData
                  };
                  return newContext;
                });
              }}
              onClose={() => {
                addBreadcrumb({
                  message: 'collapsed close: ' + context.collapsedSelected.data.meta.id
                });
  
                context.collapsedSelected.node._BlazeErrorCallbackFn = null;
                if (isMounted.current) {
                  setContext((currentValue) => Object.assign({}, currentValue, { collapsedSelected: null }));
                }
                highlighter.current.clearTokenHighlight();
              }}
              onMouseDown={() => {
                if (!('forceCollapseState' in context)) {
                  return;
                }
                // If `forceCollapseState` is enabled, then quill selection is not on the chip.
                // To make it consistent with actual command selection, we should clear it.
                // This might lead unfocus of attribute. Lets be careful of how many times we do it. 
                
                let node = context.collapsedSelected?.node;

                if (!node) {
                  return;
                }

                node.dataset.highlighted = 'true';
                
                // when click on attribute editor, clear quill selection
                let quill = getQuill(node);
                quill.setSelection(null, 'silent');

              }}
              onChange={onSidebarChange}
              onSelectCommand={(command) => {
                setContext((context) => {
                  /**
                   * @type {NodeListOf<HTMLElement>}
                   */
                  let collapsedCommands = quill.current.root.querySelectorAll('.collapsed-command');
                  let node;
                  for (const collapsed of collapsedCommands) {
                    const data = getCollapsedCommandBlot(collapsed).blazeData;
                    if (data === command) {
                      node = collapsed;
                      break;
                    }
                  }
                  const newContext = { ...context };
                  // To not clearing focus everytime.
                  delete newContext.forceCollapseState;
                  newContext.collapsedSelected = {
                    data: command,
                    node: node,
                    rootData: command
                  };
                  node.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest'
                  });
                  node.classList.add('glow');
                  setTimeout(() => {
                    // clean the class, so we can apply again.
                    // There is a problem if user does this quickly, then the user won't see
                    node.classList.remove('glow');
                  }, 1500);
                  return newContext;
                });
              }}
              getSibilings={() => {
                const el = quill.current;
                try {
                  const quillRoot = el.root;
                  let siblings = [];
                  let collapsedData = context.collapsedSelected.data;
                  if (collapsedData !== context.collapsedSelected.rootData) {
                    return [];
                  }
                  const rootStartPosition = collapsedData.meta.rootTokenStartPosition;
                  const tokenEls = /** @type {HTMLElement[]} */ ([...quillRoot.querySelectorAll('[data-blaze-id]')]);
                  const tokenIds = highlighter.current.tokenMap[rootStartPosition] || [];
                  for (const tokenEl of tokenEls) {
                    const isSibling = tokenIds.includes(tokenEl.dataset.blazeId.split('-')[0]);
                    if (!isSibling) {
                      continue;
                    }
                    const data = getCollapsedCommandBlot(tokenEl).blazeData;
                    if (!data) {
                      continue;
                    }
                    siblings.push(data);
                  }

                  let collapsedCommands = quillRoot.querySelectorAll('.collapsed-command');
                  for (const collapsed of collapsedCommands) {
                    const data = getCollapsedCommandBlot(collapsed).blazeData;
                    const highlighted = tokenIds.includes(data.meta.id);
                    if (highlighted) {
                      siblings.push(data);
                    }
                  }
                  return siblings;
                } catch (err) {
                  console.error(err, context?.collapsedSelected?.data);
                  return [];
                }
              }}
              onVariableNameChange={onVariableNameChange}
              getVariableCountInSnippet={getVariableCountInSnippet}
              onError={(errors) => {
                if (errors.size) {
                  // text does not matter anymore
                  warnOnExitForCommand('Unsaved command');
                } else {
                  cancelWarningOnCloseForCommand();
                }
                embeddedCommandError.current = errors;
                props.onEmbeddedCommandError?.(errors);
              }}
            /> : null}
            hideCommandsListToolbar={props.hideCommandsListToolbar}
          />
        
        }
      </div>
      {gridSelectorAnchor && (
        <TableGridSelector
          anchorEl={gridSelectorAnchor}
          onSelect={(rows, cols) => {
            try {
              addBreadcrumb({
                message: `Adding a table of ${rows} x ${cols}`
              });
              getBetterTable(quill.current).insertTable(rows, cols);
            } catch (err) {
              console.warn(err);
              toast('Cannot insert table - ' + err.message, {
                intent: 'danger'
              });
            }
          }}
          onClose={() => setGridSelectorAnchor(null)}
        />
      )}{
        emojiSelectorAnchor && (<EmojiSelector
          target={emojiSelectorAnchor}
          removeLabel="Remove icon"
          onClose={() => {
            setEmojiSelectorAnchor(null);
          }}
          onSelect={(emoji) => {
            insert(emoji);
            setEmojiSelectorAnchor(null);
          }}
        />)
      }
      <TableMenu
        ref={tableMenuRef}
        insert={insert}
        dialog={dialog}
      />
      {(autowriteData || openedAIWriteOnce) && <ActionContext.Provider value={{ onSubmitPrompt: onStartAIGeneration, onStreamingEnd: onStopAIGeneration }}>
        <SnippetContentContext.Provider value={{ content: autowriteData?.content, selection: autowriteData?.selection }}>
          <Suspense fallback={<AutoWriteFallback anchorEl={aiWriteAnchor.current} onClose={() => {
            setShowAutoWrite(null);
          }} />}>
            <AutoWrite
              autowriteRef={autowriteRef}
              anchorEl={aiWriteAnchor.current}
              snippetId={props.snippetId}
              groupId={props.groupId}
              onCancel={() => {
                setShowAutoWrite(null);
              }}
              onDone={(action, txt) => {
                setShowAutoWrite(null);

                if (action === 'polish') {
                  if (!autowriteData?.selection) {
                    quill.current.setSelection(0, quill.current.getLength());
                  }
                }

                // TODO: right now, snippet editor doesn't support inserting html
                // Once it supports, we can use txt.html here. Until then,
                // we need to use the plain text
                insert(txt.text);
              }}
              // User had opened the popup once but doesn't have it opened right now
              // Which implies they probably have some state that we need to preserve
              shouldHideDisplay={openedAIWriteOnce && !autowriteData}
            />
          </Suspense>
        </SnippetContentContext.Provider>
      </ActionContext.Provider>}
    </>
  );
}

function AutoWriteFallback({ anchorEl, onClose }) {
  const [showLoading, setShowLoading] = useState(false);

  useOnMount(() => {
    // Show loading indicator only after a initial timeout
    // to avoid loading jank for users on fast internet connections
    const timeout = setTimeout(() => {
      setShowLoading(true);
    }, 400);
    setShowLoading(false);
    return () => {
      clearTimeout(timeout);
    };
  });

  if (!showLoading) {
    return null;
  }

  return <Popper
    open
    anchorEl={anchorEl}
    sx={{
      zIndex: (theme) => theme.zIndex.modal,
      background: 'white',
    }}
  >
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onClose}>
      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <CircularProgress size={120} thickness={1.9} />
      </div>
    </ClickAwayListener>
  </Popper>;
}


/**
 * Returns the Better Table instance
 * @param {Quill} quill 
 */
const getBetterTable = (quill) => {
  return /** @type {import('quill-better-table').default} */ (quill.getModule('better-table'));
};