import React from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { joinOrganization, clearNotification } from '../../data';
import T from '@mui/material/Typography';
import moment from 'moment';
import { toast } from '../../message';
import { log } from '../../logging/logging';
import { firebaseUser, waitForLogin, IS_MICRO } from '@store';
import { sendEmailVerification } from 'firebase/auth';
import { getState } from '../../getState';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { APP_TARGET, CURRENT_PLATFORM, isBlaze, orgPref } from '../../flags';
import { ReferIcon } from '../ReferDialog/refer_utils';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import './notifications.css';
import { getProperCurrentBrowserName } from '../../extension';
import AppLink from '../Utilities/AppLink';
import { isAiBlaze } from '../../aiBlaze';
import Tag from './Tag';
import EnableGroupButton from './EnableGroupButton';
import ActionButton from './ActionButton';
import { updateKeyboardShortcut } from './notifications_utilities';
import { NotificationDialog } from './NotificationDialog.jsx';
import { randomize } from '../../experiment/experiment.js';

import formFieldsVideo from '../../../images/videos/form_fields.mp4';
import createSnippetVideo from '../../../images/videos/create_snippet.mp4';
import sharingVideo from '../../../images/videos/sharing.mp4';



/**
 * Takes all keys on an object and returns keys related to notifications.
 * 
 * @param {string[]} keys 
 * 
 * @return {string[]}
 */
function filterKeys(keys) {
  return keys.filter(x => x.includes('___'));
}


/**
 * @param {object|null} notification
 * @param {React.ReactElement|string} title 
 * @param {React.ReactElement|string} description
 * @param {({url?: string, title?: string, dialogInfo?: {dialogTitle: string, videoSrc:string, buttonTitle: string}} | Object)[]} actions
 * @param {number} number
 */
function createContents(notification, title, description, actions, number) {
  actions = (actions || []).slice();
  actions = actions.map(action => {
    if (action.dialogInfo) {
      return <NotificationDialog
        key={`learn_more_${notification.key}`}
        notificationKey={notification.key}
        buttonTitle={action.dialogInfo.buttonTitle}
        dialogTitle={title}
        dialogButtonTitle={action.title}
        videoSrc={action.dialogInfo.videoSrc}
        description={description}
        url={action.url}
      />;
    } else if (action.url && action.title) {
      return <ActionButton
        key={`learn_more_${notification.key}`}
        notificationKey={notification.key}
        url={action.url}
      >
        {action.title}
      </ActionButton>;
    } else {
      return action;
    }
  });

  let hasTime = notification && (!notification.key.startsWith('local___'));

  return <Card
    key={notification ? notification.key : 'email'}
    elevation={1}
    square
    sx={{
      width: {
        xs: '300px',
        sm: '340px'
      },
      marginTop: number > 0 ? 1 : 0,
      position: 'relative'
    }}>
    {notification && <IconButton
      style={{
        position: 'absolute',
        top: 5,
        right: 6,
        opacity: .7
      }}
      size="small"
      title="Dismiss"
      onClick={async () => {
        await waitForLogin();
        clearNotification(notification.key);
      }}
    ><CloseIcon fontSize="small" /></IconButton>}
    <CardContent style={{ paddingBottom: 5 }}>
      <T variant="subtitle1" style={{ marginBottom: 8 }}>{title}</T>
      <T variant="body2" style={{
        wordBreak: 'break-word'
      }}
      component="div">
        {description}
      </T>
    </CardContent>
    <CardActions>
      {hasTime && <T variant="caption" color="textSecondary" style={{
        float: 'left',
        whiteSpace: 'nowrap',
        paddingLeft: 8
      }}>{moment(notification.timestamp).fromNow()}</T>}
      <div style={{ width: '100%', textAlign: 'right' }}>
        {actions}
      </div>
    </CardActions>
  </Card>;
}

updateKeyboardShortcut();

/** @type {object[]} */
const LOCAL_NOTIFICATIONS = [
  {
    id: 'local___assistant_firstrun',
    title: <span>Welcome to the Text Blaze Assistant</span>,
    shouldShow: (userState) => {
      return IS_MICRO && moment(userState.firebaseMetadata.creationTime).isAfter(moment('2019-10-10'));
    },
    contents: () => <span>
      The assistant supports full keyboard access to quickly find and insert snippets.
      <span style={{ display: 'block', height: 10 }}></span>
      Simply start typing to find your snippet, and then press Enter to directly insert it.
    </span>,
  },
  {
    id: 'local___referred_user',
    title: <span><ReferIcon width={20} /> Try Text Blaze Pro on Us</span>,
    shouldShow: (userState) => {
      if (userState.is_pro || (userState.org && userState.org.id) || !userState.sign_up_code) {
        return false;
      }
      if (!userState.sign_up_code.toUpperCase().startsWith('U')) {
        // not a referrer code
        return false;
      }
      if (userState.sign_up_code.toUpperCase().endsWith('N')) {
        // Part of credit referral program
        return false;
      }
      if (!userState.pro_grant_expiry) {
        return false;
      }
      return moment({ hours: 0 }).diff(userState.firebaseMetadata.creationTime, 'days') < 4;
    },
    contents: (userState) => {
      let benefit = 'a free month';
      const grantMonths = moment(userState.pro_grant_expiry.toDate()).diff(userState.firebaseMetadata.creationTime, 'months');
      
      if (grantMonths > 1) {
        benefit = grantMonths + ' months';
      }
      return <span>Welcome to Text Blaze! Because you were invited, enjoy {benefit} of Text Blaze Pro.<span style={{ display: 'block', height: 10 }}></span>Try out great Pro features like images in snippets and full access to <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms</a>.</span>;
    }
  }, {
    id: 'local___pro_grant_expiring',
    title: 'Upgrade to Keep Pro Features',
    shouldShow: (userState) => {
      if (userState.is_pro || (userState.org && userState.org.id) || !userState.pro_grant_expiry) {
        return false;
      }

      if (isAiBlaze) {
        return false;
      }
      
      let daysToExpiry = -moment({ hours: 0 }).diff(userState.pro_grant_expiry.toDate(), 'days');
      return daysToExpiry < 10 && daysToExpiry > 2;
    },
    contents: (userState) => <span>Your free Text Blaze Pro trial is ending on {moment(userState.pro_grant_expiry.toDate()).format('LL')}. Upgrade now to the Pro plan to keep Pro features. (We won't start billing you until your free trial ends.)</span>,
    buttons: [
      {
        url: '/upgrade',
        title: 'Upgrade'
      }
    ]
  }, {
    id: 'local___pro_grant_expiring_alert',
    title: '⚠️ You Will Lose Pro Features Soon',
    shouldShow: (userState) => {
      if (userState.is_pro || (userState.org && userState.org.id) || !userState.pro_grant_expiry) {
        return false;
      }

      if (isAiBlaze) {
        return false;
      }

      let daysToExpiry = -moment({ hours: 0 }).diff(userState.pro_grant_expiry.toDate(), 'days');
      return daysToExpiry <= 2 && daysToExpiry >= 0;
    },
    contents: (userState) => <span>Your free Text Blaze Pro trial is ending on {moment(userState.pro_grant_expiry.toDate()).format('LL')}. Upgrade to Pro now to keep Pro features. (We won't start billing you until your free trial ends.)</span>,
    buttons: [
      {
        url: '/upgrade',
        title: 'Upgrade'
      }
    ]
  }, {
    id: 'local___update_2024_sept',
    title: '🎉  September Text Blaze Updates',
    shouldShow: (userState) => moment(userState.firebaseMetadata.creationTime).add(20, 'd').isBefore(moment()) && (new Date()).toISOString() >= '2024-09-01' && (new Date()).toISOString() < '2024-09-24',
    contents: <span>Say hello to <a target="_blank" rel="noreferrer" href="https://chromewebstore.google.com/detail/ai-blaze-instantly-use-ai/cebmnlammjhancocbbnfcglifgdpfejc">AI Blaze</a>, the fastest way to use AI on any website.</span>,
    buttons: [
      {
        url: 'https://community.blaze.today/t/september-development-update/40559',
        title: 'Learn more'
      }
    ]
  }
];


const tip = <Tag round>Tip</Tag>;

/** @typedef {{ id: string, check: Function, generate: (userState) => { title: JSX.Element, contents: JSX.Element, buttons: ({url?: string, title?: string, dialogInfo?: {videoSrc:string, buttonTitle: string}})[] }, channel: string, delay?: number, platforms?: Partial<Record<SupportedPlatformNames, boolean>>}} NotificationType */
/** @type {NotificationType[]} */
const TUTORIALS_DATA = [
  {
    id: 'context_menu_use',
    check: (data) => data.UCM === false,
    generate: () => {
      return {
        title: <span>{tip} Right-click to insert snippets</span>,
        contents: <span>You can view all your snippets and choose what to insert with the Text Blaze context menu. Right-click on any text field and select which snippet to insert without needing to remember its shortcut.</span>,
        buttons: []
      };
    },
    channel: 'TEXT',
    platforms: {
      browser: true
    }
  },
  {
    id: 'use_clipboard',
    check: (data, commandWhitelist) => !data.commands.includes('clipboard') && (!commandWhitelist || commandWhitelist.includes('CLIPBOARD')),
    generate: () => {
      return {
        title: <span>{tip} Include the clipboard in a snippet</span>,
        contents: <span>Use the <Tag minimal>{'{clipboard}'}</Tag> command to include the current contents of your clipboard in your snippets. Use the toolbar below the snippet edit area to insert this and other dynamic commands.</span>,
        buttons: [{
          url: 'https://blaze.today/commands/clipboard/',
          title: 'Learn more'
        }]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'use_time',
    check: (data, commandWhitelist) => !data.commands.includes('time') && (!commandWhitelist || commandWhitelist.includes('TIME')),
    generate: () => {
      return {
        title: <span>{tip} Dynamic time and dates</span>,
        contents: <span>Use the <Tag minimal>{'{time}'}</Tag> command to include the current or future time or date in your snippets. Use whatever formatting you would like. For example, <Tag minimal>{'{time: YYYY}'}</Tag> will insert the current year.</span>,
        buttons: [{
          url: 'https://blaze.today/guides/time/',
          title: 'Learn more'
        }]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'new_snippet_group',
    check: (data) => data.NS === false || data.NG === false,
    generate: () => {
      return {
        title: <span>{tip} Create new snippets and folders</span>,
        contents: <span>You can create new snippets using the large <Tag round minimal>+</Tag> button in the upper left of the dashboard window. You can also create new snippet folders and drag and drop snippets between folders.</span>,
        buttons: [
          {
            dialogInfo: {
              videoSrc: createSnippetVideo,
              buttonTitle: 'Watch short video',
            }
          }
        ]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'use_forms',
    check: (data, commandWhitelist) => data.FF === false && (!commandWhitelist || commandWhitelist.includes('FORMTEXT')),
    delay: 2,
    generate: (userState) => {
      const showDialogNotification = isPartOfNotificationsWithVideoExperiment(userState);

      return {
        title: <span>{tip} Use forms in snippets</span>,
        contents: <span>Include text fields, menus and toggles in your snippets with Text Blaze forms.</span>,
        buttons: [{
          url: 'https://blaze.today/guides/forms/',
          title: 'Learn more',
          dialogInfo: showDialogNotification
            ? {
              videoSrc: formFieldsVideo,
              buttonTitle: 'Watch short video',
            }
            : undefined
        }]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'use_autopilot',
    check: (data, commandWhitelist) => !data.commands.includes('key') && !data.commands.includes('click') && (!commandWhitelist || commandWhitelist.includes('KEY')),
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Simulate key presses and clicks</span>,
        contents: <span>Carry out actions in a webpage with the <Tag minimal>{'{key}'}</Tag> and <Tag minimal>{'{click}'}</Tag> commands. For example, <Tag minimal>{'{key: tab}'}</Tag> can be used to tab between text fields.</span>,
        buttons: [{
          url: 'https://blaze.today/guides/autopilot/',
          title: 'Learn more'
        }]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'share_groups',
    check: (data) => data.SG === false,
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Share your snippet folders</span>,
        contents: <span>Stay in sync with your team by sharing your snippet folders. Changes to snippets are shared automatically. To share, click on a folder and select the <b>Sharing</b> tab.</span>,
        buttons: [{
          dialogInfo: {
            videoSrc: sharingVideo,
            buttonTitle: 'Watch short video',
          }
        }]
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'create_from_selection',
    check: (data) => data.CSFS === false,
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Make snippet from the selection</span>,
        contents: <span>Select text on any page (like an email you just wrote) and right-click on it to use the Text Blaze context menu to make a new snippet from the selection.</span>,
        buttons: []
      };
    },
    channel: 'TEXT',
    platforms: {
      browser: true
    }
  },
  {
    id: 'group_trigger',
    check: (data) => data.EGT === false,
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Adjust when snippets trigger</span>,
        contents: <span>By default, snippets will only trigger if you type their shortcut at the start of a new word. You can allow snippets to trigger mid-word by editing the trigger option on their folder.</span>,
        buttons: []
      };
    },
    channel: 'TEXT'
  },
  {
    id: 'omnibox_use',
    check: (data) => data.UO === false,
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Insert URLs in the URL bar</span>,
        contents: <span>Text Blaze can insert URLs into {getProperCurrentBrowserName()}'s URL bar. Just type <b>tb</b> followed by a space and then the snippet shortcut.</span>,
        buttons: []
      };
    },
    channel: 'TEXT',
    platforms: {
      browser: true
    }
  },
  {
    id: 'datablaze_command',
    check: (data, commandWhitelist) => !data.commands.includes('dbselect') && !data.commands.includes('dbinsert') && !data.commands.includes('dbdelete') && !data.commands.includes('dbupdate') && (!commandWhitelist || commandWhitelist.includes('dbselect')),
    delay: 3,
    generate: () => {
      return {
        title: <span>{tip} Connect your Snippets to Data Blaze</span>,
        contents: <span>Easily customize your snippets or save data using Data Blaze.</span>,
        buttons: [{
          url: 'https://blaze.today/datablaze/docs/db_and_snippets/',
          title: 'Learn more'
        }]
      };
    },
    channel: 'TEXT'
  },

  // DATA Blaze
  {
    id: 'form_view_use',
    check: (_data) => true, // TODO add check for form usage 
    delay: 2,
    generate: () => {
      return {
        title: <span>{tip} Create Form Views to Collect Data</span>,
        contents: <span>Publish a form view you can share to collect survey responses and add them to your space</span>,
        buttons: [{
          url: 'https://blaze.today/datablaze/docs/views/#form-view',
          title: 'Learn more'
        }]
      };
    },
    channel: 'DATA'
  },
].filter(notification => !notification.platforms || notification.platforms[CURRENT_PLATFORM]);


function tutorialNotifications(userState, dismissed_notifications, commandWhitelist) {
  if (!userState.usage) {
    return [];
  }
  let app = APP_TARGET;
  let res = [];

  let usage = userState.usage;

  usage.commands = usage.commands || [];

  const AGE = moment({ hours: 0 }).diff(userState.firebaseMetadata.creationTime, 'days');

  const MAX = 1;
  const PREFIX = 'local___tutors_';
  let surfacing = [];

  // No channel, means apply everywhere
  let filteredTutorials = TUTORIALS_DATA.filter(x => !x.channel || x.channel === app);

  let delay = 0;
  for (let i = 0; i < filteredTutorials.length; i++) {
    let tutorial = filteredTutorials[i];
    delay += tutorial.delay || 1;
    if (delay > AGE) {
      // We throttle notifications and have them start after a day
      break;
    }

    if (!dismissed_notifications.includes(PREFIX + tutorial.id) && tutorial.check(usage, commandWhitelist)) {
      surfacing.push(tutorial);
      if (surfacing.length >= MAX) {
        break;
      }
    }
  }

  if (surfacing.length) {
    let notifications = surfacing.map(x => Object.assign(
      x.generate(userState),
      { id: PREFIX + x.id }
    ));
    for (let notification of notifications) {
      res.push(notification);
    }
  }

  return res;
}


let localsCached = null;


/**
 * @param {boolean} quiet - if true, only return important notifications
 *
 * @return {{ notifications: React.ReactElement[], showCount: number }}
 */
export function createNotifications(quiet) {
  let state = getState();

  if (!state.userState || !state.userState.settingsLoaded || !state.userState.readonlyLoaded || !state.userState.firebaseMetadata) {
    return { notifications: [], showCount: 0 };
  }

  if (state.userState.org && !state.orgState.org) {
    // We want to wait for the org to load so we can decide what to load
    return { notifications: [], showCount: 0 };
  }


  let notifObj = state.userState.notifications || {};

  let needsEmailVerification = !state.userState.emailVerified && state.userState.email;

  let commandWhitelist = orgPref(state, 'shouldWhitelistCommands') && (orgPref(state, 'commandWhitelist') || []);

  let userState = state.userState;
  let app = APP_TARGET;

  if (!localsCached || localsCached.uid !== userState.uid || localsCached.createdAt + 30 * 60 * 1000 < Date.now() || localsCached.app !== app) {
    let notifs = [];
    localsCached = {
      uid: userState.uid,
      app,
      createdAt: Date.now(),
      notifications: notifs.concat(tutorialNotifications(userState, userState.dismissed_notifications || [], commandWhitelist))
    };
  }
  let locals = LOCAL_NOTIFICATIONS.concat(localsCached.notifications);


  notifObj = Object.assign({}, notifObj);
  // don't show text blaze local notifications for AI Blaze
  if (userState && userState.settingsLoaded && !isAiBlaze) {
    for (let local of locals) {
      if (!userState.dismissed_notifications || !userState.dismissed_notifications.includes(local.id)) {
        if (!local.shouldShow || local.shouldShow(userState)) {
          if (!quiet || !local.quite) {
            notifObj[local.id] = local;
          }
        }
      }
    }
  }
  let keys = Object.keys(notifObj);

  keys = filterKeys(keys); // exclude items like updated_at, updated_by

  let notifications = [];
  for (let key of keys) {
    let [type, id] = key.split('___');
    notifications.push(Object.assign({ type, id, key }, notifObj[key]));
  }
  notifications.sort((a, b) => b.timestamp - a.timestamp);

  let count = 0;

  let emailNotification = [];
  if (needsEmailVerification && !quiet) {
    emailNotification.push(createContents(null, 'Verify your Email',
      <span>You must verify your email address <b>{needsEmailVerification}</b> in order to use some {isAiBlaze ? 'AI' : 'Text'} Blaze features like folder sharing.</span>,
      [
        <AsyncButton
          key="resend"
          variant="outlined"
          color="primary"
          size="small"
          onClick={async (done) => {
            await waitForLogin();
            sendEmailVerification(firebaseUser()).then(() => {
              toast(`Verification email resent to (${needsEmailVerification}). Follow the instructions in it to verify your address.`, {
                duration: 6000,
                intent: 'success'
              });
              log({ category: 'Authentication', action: 'Sent verification email' });
              done();
            }).catch((error) => {
              toast(`Verification email could not be sent to (${needsEmailVerification}). ${error}`, {
                duration: 6000,
                intent: 'danger'
              });
              log({ category: 'Authentication', action: 'Failed to send verification email' });
              done();
            });
          }}
        >Resend verification email</AsyncButton>
      ], count++
    ));
  }


  let topNotifications = emailNotification.concat(notifications.map(n => {
    if (n.type === 'followup') {
      if (n.timestamp < Date.now()) {
        return createContents(n, 'Followup',
          <span>{n.message}</span>, [], count++);
      } else {
        // not time to show yet
        return null;
      }
    } else if (n.type === 'group_share') {
      return createContents(n, 'Folder shared with you',
        // Open the group link in a new page when we're in the micro assistant
        <span>The folder <b><AppLink appType="TEXT" to={'/folder/' + n.id} target={IS_MICRO ? '_blank' : undefined} rel="noreferrer">{n.group_name}</AppLink></b> has been shared with you by <b>{n.sharer}</b>. Do you want to enable it?</span>, [
          <EnableGroupButton id={n.id} key="add" />
        ],
        count++
      );
    } else if (n.type === 'org_invite') {
      return createContents(n, 'Business organization invite',
        <span>You have been invited to join <b>{n.org_name}</b> by <b>{n.sharer}</b>. Do you want to join the organization? Doing so will give that organization the ability to manage your account.</span>, [
          <AsyncButton
            variant="outlined"
            color="primary"
            size="small"
            key="join"
            style={{ marginLeft: 10 }}
            onClick={async (done) => {
              if (userState.org && userState.org.id) {
                toast('You are already in an organization and must leave that one before joining this one.', {
                  duration: 6000,
                  intent: 'danger'
                });
                return done();
              } else {
                await waitForLogin();
                joinOrganization(n.id).then(done, done);
              }
            }}
          >
            Join
          </AsyncButton>
        ],
        count++
      );
    } else if (n.type === 'referral_signup') {
      return createContents(n, <span><ReferIcon width={20} /> New referral sign-up</span>,
        <span><b>{n.who}</b> has signed up for Text Blaze. If they are using it in a week, you'll get one free month of Text Blaze Pro on us.</span>, [], count++);
    } else if (n.type === 'referral_signup_for_credit') {
      return createContents(n, <span><ReferIcon width={20} /> New referral sign-up</span>,
        <span><b>{n.who}</b> has signed up for Text Blaze. You will get $10 in credits, If they verify their email.</span>, [], count++);
    } else if (n.type === 'referral_accept') {
      let whose;
      if (n.who.length > 1) {
        let who = n.who.slice();
        let last = who.pop();
        whose = <>{who.map((w, i) => <span key={i}><b>{w}</b>, </span>)} and <b>{last}</b></>;
      } else {
        whose = <b>{n.who}</b>;
      }
      return createContents(n, <span><ReferIcon width={20} /> Referral{n.who.length > 1 ? 's' : ''} awarded</span>,
        <span>{whose} {n.who.length > 1 ? 'have' : 'has'} become {n.who.length > 1 ? '' : 'an'} active Text Blaze user{n.who.length > 1 ? 's' : ''}. Thank you for referring them! Enjoy {n.who.length} free month{n.who.length > 1 ? 's' : ''} of Text Blaze Pro on us.</span>, [], count++);
    } else if (n.type === 'referral_accept_for_credit') {
      let whose;
      if (n.who.length > 1) {
        let who = n.who.slice();
        let last = who.pop();
        whose = <>{who.map((w, i) => <span key={i}><b>{w}</b>, </span>)} and <b>{last}</b></>;
      } else {
        whose = <b>{n.who}</b>;
      }
      return createContents(n, <span><ReferIcon width={20} /> Referral{n.who.length > 1 ? 's' : ''} awarded</span>,
        <span>{whose} {n.who.length > 1 ? 'have' : 'has'} become {n.who.length > 1 ? '' : 'an'} active Text Blaze user{n.who.length > 1 ? 's' : ''}. Thank you for referring them! You earned ${(n.who.length) * (10)} in credits</span>, [], count++);
    } else if (n.type === 'local') {
      return createContents(n, n.title, n.contents instanceof Function ? n.contents(userState) : n.contents, n.buttons, count++);
    } else {
      // Continue
      // We don't throw an error, as this could break old clients that are open
      return null;
    }
  }).filter(x => !!x));


  return {
    showCount: topNotifications.length,
    notifications: topNotifications
  };
}

export function isPartOfNotificationsWithVideoExperiment(userState) {
  if (IS_MICRO) {
    return false;
  }

  if (!userState?.isLoaded) {
    return false;
  }

  if (isBlaze(userState)) {
    return true;
  }

  return randomize('Notifications dialog', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [0.50, 0.50]
  }) === 'show';
}