import React, { Suspense, useState, useEffect, useCallback } from 'react';
import { checkAnyAppInstalled, checkExtensionInstalled, getExtensionType, getProperCurrentBrowserName } from '../../extension';
import Callout from '../Callout/Callout';
import AsyncButton from '../AsyncButton/AsyncButton';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Scratch from './Scratch';
import Hero from './Hero';
import { createNotifications } from '../Notifications/notifications';
import WarningIcon from '@mui/icons-material/Warning';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DocsIcon from '@mui/icons-material/HelpOutline';
import FAQIcon from '@mui/icons-material/FindInPageOutlined';
import ForumIcon from '@mui/icons-material/ForumOutlined';
import EmailIcon from '@mui/icons-material/AlternateEmailOutlined';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Paper from '@mui/material/Paper';
import {
  useTypedSelectorShallowEquals,
  useTypedSelector,
} from '../../hooks';
import PlaceholderOrgBanner from '../PlaceholderOrg/PlaceholderOrgBanner';
import Box from '@mui/material/Box';
import { IconButton, Zoom } from '@mui/material';
import { isOrg, isPro, store, usersSettingsRef } from '@store';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import crownIcon from '../App/CrownIcon';
import { storage } from '../../utilities';
import { arrayUnion } from 'firebase/firestore';
import { CURRENT_PLATFORM, DESKTOP_APP_NAME, checkExtensionDefaultChoice, isElectronApp } from '../../flags';
import AppConflict from '../AppConflict/AppConflict';
import ReferralPrompt from './ReferralPrompt';
import { installDesktopApp } from '../../desktop_shared_utils';
import { installExtension } from '../../install_extension';
import { isAiBlaze } from '../../aiBlaze';
import { fullAppName, isElectronFlag, isMacPlatform } from '../../raw_flags';
// @ts-ignore
import sidebarPrompt from '../../../images/ai_sidebar_prompt.png';
// @ts-ignore
import sidebarPrompt2 from '../../../images/ai_sidebar_prompt_2.png';
// @ts-ignore
import aiBlazeIcon from '../../../images/ai_blaze.webp';
import { usePushAISidebar } from '../../hooks/usePushAISidebar';
import { COMPLETED, DISMISSED } from '../Walkthrough/walkthrough_constant';

const Walkthrough = React.lazy(() => import('../Walkthrough/Walkthrough'));
const DesktopWalkthrough = React.lazy(() => import('../Walkthrough/DesktopWalkthrough'));

function ListItemLink(props) {
  return <ListItem button component="a" target="_blank" {...props} />;
}

const isExtensionDefaultChoice = checkExtensionDefaultChoice();

let hasZoomedTourButton = false;


const UPGRADE_PRO_WITH_CREDIT = '%APP_upgrade_pro_with_credit';
function WelcomeBase() {
  let [anyAppInstalled, setAnyAppInstalled] = useState(true);
  let [isExtensionInstalled, setExtensionInstalled] = useState(true);
  let [closeProUpgrade, setCloseProUpgrade] = useState(false);
  let userEmail = useTypedSelector(store => store.userState.isLoaded && store.userState.email);
  let walkthroughEarlyDismissal = useTypedSelector(store => store.uiState.walkthroughEarlyDismissal);

  let settingsLoaded = useTypedSelector(store => store.userState && store.userState.settingsLoaded);
  let aiBlazePushSidebar = usePushAISidebar();


  const updateInstallState = useCallback(async function() {
    const isInstalledAnyApp = await checkAnyAppInstalled(userEmail);
    setAnyAppInstalled(isInstalledAnyApp);
    const isInstalledExt = await checkExtensionInstalled();
    setExtensionInstalled(isInstalledExt);

    // let other components know we activated it
    if (isInstalledAnyApp) {
      store.dispatch({
        type: 'USER_ACTIVATED_APP_OR_EXTENSION',
      });
    }
  }, [userEmail]);

  useEffect(() => {
    updateInstallState();
  }, [updateInstallState]);


  let { readonlyLoaded, quest, notifications, balance, dismissedProUpgradeWithReferral, signedUpAfter11Aug } = useTypedSelectorShallowEquals((store) => {
    let notifications;
    if (store.userState && store.userState.settingsLoaded && store.userState.readonlyLoaded) {
      notifications = createNotifications(true).notifications;
    }
    const loggedIn = store.userState.createdTimestamp;
  
    return {
      readonlyLoaded: store.userState && store.userState.readonlyLoaded, // readonly data is used by hasInserted
      quest: store.userState && store.userState.quest,
      notifications: notifications || null,
      balance: store.userState ? store.userState.credit_balance : 0,
      dismissedProUpgradeWithReferral: store.userState.dismissed_notifications && store.userState.dismissed_notifications.includes(UPGRADE_PRO_WITH_CREDIT),
      // After 11 aug
      signedUpAfter11Aug: loggedIn >= Date.UTC(2023, 7, 11)
    };
  });

  let showCreditInvitePopup = signedUpAfter11Aug && !isOrg();
  let showBalanceAndProUpgrade = false;
  if (!isPro() && !isOrg() && balance) {
    showBalanceAndProUpgrade = !dismissedProUpgradeWithReferral;
  }
  function installPrompt() {
    return <Callout
      icon={<WarningIcon />}
      warning
      title={(isExtensionDefaultChoice ? getExtensionType() : DESKTOP_APP_NAME) + ' not installed'}
      style={{ flex: 1 }}
    >
      <T paragraph>To use {fullAppName}, you need to install our free {isExtensionDefaultChoice ? getExtensionType() : DESKTOP_APP_NAME}. It only takes a few seconds. Click the button below and confirm in the next window.</T>
      {isExtensionDefaultChoice && <T paragraph>To insert {isAiBlaze ? 'prompts' : 'snippets'}, {fullAppName} needs to be able to read and change data on the websites you visit.</T>}
      <p style={{ textAlign: 'right' }}>
        <AsyncButton onClick={(done) => updateInstallState().finally(done)}>Check again</AsyncButton>&nbsp;&nbsp;
        {isExtensionDefaultChoice ? <Button onClick={() => installExtension()} color="primary" variant="contained" style={{
          marginLeft: 12
        }}>Add extension to {getProperCurrentBrowserName()}</Button> : <Button
          onClick={() => {
            installDesktopApp();
          }}
          color="primary" variant="contained" style={{
            marginLeft: 12
          }}
        >Install {DESKTOP_APP_NAME}</Button>}
      </p>
    </Callout>;
  };

  const faqLink = isElectronApp() ? `https://blaze.today/${CURRENT_PLATFORM}/faq/` : 'https://blaze.today/faq/';

  return (
    <div style={{
      height: '100%',
      position: 'relative',
      overflow: 'auto',
      display: 'flex',
      alignItems: 'flex-start',
    }}>
      <Box
        sx={{
          flex: 1,
          paddingTop: 3,
          paddingLeft: 2,
          paddingRight: 2,
          overflow: 'auto',
          height: '100%',
          maxHeight: 'calc(100vh - 48px)'
        }}
      >
        <div style={{
          minHeight: 'calc(100% - 150px)'
        }}>
          <AppConflict />

          {!anyAppInstalled
            ? installPrompt()
            : (settingsLoaded && (
              aiBlazePushSidebar && !isElectronApp()
                ? <>
                  {aiBlazePushSidebar === 'OPEN' ? <>
                    <img
                      alt="Try the sidebar"
                      src={sidebarPrompt}
                      style={{
                        position: 'fixed',
                        right: 70,
                        top: 'calc(80% - 60px)',
                        width: 460
                      }} />
                  </> : <>
                    <img
                      alt="Use the sidebar"
                      src={sidebarPrompt2}
                      style={{
                        position: 'fixed',
                        right: 320,
                        top: 'calc(80% - 160px)',
                        width: 460
                      }}
                    />
                    <Button sx={{
                      position: 'fixed',
                      right: 320,
                      bottom: '20px',
                    }} onClick={() => {
                      // TODO: remove in October start
                      // This is only an escape hatch for users on older extensions
                      storage.update(usersSettingsRef, {
                        'options.ai_sidebar_used': true,
                      });
                    }}>Skip this step</Button>
                  </>}
                </>
                : <div style={{
                  flex: 1,
                  borderRadius: 10,
                  marginRight: 20
                }}>
                  {isAiBlaze ? <>
                    <T variant="h4" sx={{
                      mb: 4
                    }}>
                      Welcome to AI Blaze
                    </T>
                    <T variant="h5" paragraph sx={{
                      mb: 4
                    }}>Don't forget, there are two ways to use AI Blaze {isElectronFlag ? 'in any application' : 'on any page'}.</T>

                    <T variant="h6" paragraph>1️⃣ Use the AI Blaze sidebar</T>

                    <T>Just click on the <img src={aiBlazeIcon} alt="icon" style={{
                      width: 28, height: 28, verticalAlign: 'middle', marginLeft: 6, marginRight: 6 }} /> icon {isElectronFlag ? (isMacPlatform() ? 'in the menubar' : 'in the systray') : 'on the bottom right of any page'} to open the AI Blaze sidebar.</T>


                    <T variant="h6" sx={{
                      mt: 4
                    }} paragraph>2️⃣ Type an AI prompt shortcut</T>

                    <T sx={{ mb: 2 }}>Type the prompt's shortcut in any {isElectronFlag ? 'application' : 'webpage'} to instantly activate it. Typing it in a text box let's you to polish the box's text.</T>

                  </> : <Scratch />}
                </div>
            )
            )
          }

          {
            !isElectronApp() || isAiBlaze ?
              (
                quest &&
                quest.mode === 'walkthrough' &&
                !quest[DISMISSED]
                && (!isAiBlaze || !aiBlazePushSidebar)
              ) &&
              <Suspense fallback={null}>
                <ErrorBoundary dialog details="in the walkthrough">
                  <Walkthrough installed={isExtensionInstalled || isElectronApp()} />
                </ErrorBoundary>
              </Suspense> :
              !isAiBlaze && <Suspense fallback={null}>
                <ErrorBoundary dialog details="in the walkthrough">
                  <DesktopWalkthrough />
                </ErrorBoundary>
              </Suspense>
          }

        </div>
      </Box>


      {aiBlazePushSidebar ? /* Hide the landing blaze sidebar when push AI blaze sidebar */ null : <Box
        sx={{
          overflow: 'auto',
          maxHeight: 'calc(100vh - 48px)',
          display: {
            xs: 'none',
            xm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block'
          }
        }}
      >
        <div style={{
          width: 340,
          paddingTop: 24,
          paddingBottom: 24,
          marginLeft: 8,
          marginRight: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 25
        }}>
          {readonlyLoaded && <>
            {/* Don't show notification if user needs to install extension. Reduce mental overhead */}
            {anyAppInstalled && notifications}

            <PlaceholderOrgBanner />

            {!closeProUpgrade && showBalanceAndProUpgrade && !isAiBlaze && <Paper variant="outlined" style={{
              padding: '16px 16px',
              position: 'relative',
              overflow: 'auto'
            }} data-testid="redeem-credits">
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 6,
                  opacity: .7
                }}
                size="small"
                title="dismiss"
                onClick={() => {
                  setCloseProUpgrade(true);
                  storage.update(usersSettingsRef, {
                    dismissed_notifications: arrayUnion(UPGRADE_PRO_WITH_CREDIT)
                  }, 'HIDE_AUTOSAVE');
                }}
              > <CloseIcon fontSize="small" /> </IconButton>
              <T variant="h6">Redeem your credits</T>
              <div style={{ textAlign: 'center', marginTop: 12, marginBottom: 16 }}>
                <Link to="/pro">
                  <Button
                    size="large"
                    startIcon={crownIcon({
                      width: 20,
                      height: 20,
                      color: 'green'
                    })}
                    color="success"
                    variant="outlined"
                  >Upgrade to Pro</Button>
                </Link>
              </div>
              <div style={{ textAlign: 'center' }}>
                <T variant="body1"> You have <b>${balance}</b> credits in your account</T>
                <Button
                  style={{ marginTop: 10 }}
                  size="small"
                  href="#refer"
                >
                  Get more credits
                </Button>
              </div>

            </Paper>}

            {showCreditInvitePopup && !showBalanceAndProUpgrade && !isAiBlaze && <ReferralPrompt />}
            <Paper variant="outlined" style={{
              padding: '16px 16px',
              position: 'relative',
              overflow: 'auto'
            }}>
              <T variant="h6">
                {isAiBlaze ? 'Need more help?' : 'Want to learn more?'}
              </T>

              <List dense>

                {walkthroughEarlyDismissal && <Zoom in appear={!hasZoomedTourButton} onEntered={() => hasZoomedTourButton = true}>
                  <div>
                    <ListItemLink dense onClick={() => {
                      storage.update(usersSettingsRef, {
                        ['quest.' + DISMISSED]: false,
                        ['quest.' + COMPLETED]: false
                      }, 'HIDE_AUTOSAVE');
                    }}>
                      <ListItemIcon sx={{ fontSize: '2em' }}>
                  🔥
                      </ListItemIcon>
                      <ListItemText primary="Finish the quick tour" secondary="It will just take a minute" />
                    </ListItemLink>
                  </div>
                </Zoom>
                }

                {!isAiBlaze && <ListItemLink dense href="https://blaze.today/docs/">
                  <ListItemIcon>
                    <DocsIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Text Blaze documentation" secondary="Guides and references" />
                </ListItemLink>}

                {!isAiBlaze && <ListItemLink dense href={faqLink}>
                  <ListItemIcon>
                    <FAQIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Text Blaze FAQ" secondary="Frequently asked questions" />
                </ListItemLink>}

                {!isAiBlaze && <ListItemLink dense href="https://community.blaze.today/">
                  <ListItemIcon>
                    <ForumIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Community forums" secondary="Ask questions and suggest features" />
                </ListItemLink>}

                <ListItemLink dense href="mailto:support@blaze.today">
                  <ListItemIcon>
                    <EmailIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Email the team" secondary="support@blaze.today" />
                </ListItemLink>
              </List>

              <div style={{ flex: 1 }}></div>
              {!isAiBlaze && <Hero />}

            </Paper>
          </>
          }
        </div>
      </Box>}
    </div>
  );
}


const Welcome = React.memo(WelcomeBase);
export default Welcome;
