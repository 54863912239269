import React, { useState } from 'react';
import { Button, DialogContentText, IconButton, Tooltip } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTypedSelector } from '../../hooks.js';
import { clearNotification } from '../../data.jsx';
import { isPartOfNotificationsWithVideoExperiment } from './notifications.jsx';
import ActionButton from './ActionButton.jsx';
import { Box } from '@mui/system';
import { log } from '../../logging/logging';
import CloseIcon from '@mui/icons-material/Close.js';

/**
 * @param {Object} props
 * @param {string} props.notificationKey
 * @param {string} props.dialogButtonTitle
 * @param {React.ReactElement|string} props.description
 * @param {string} props.buttonTitle
 * @param {React.ReactElement|string}  props.dialogTitle
 * @param {string} props.videoSrc
 * @param {string=} props.url
 */
export function NotificationDialog({ notificationKey, description, buttonTitle, dialogTitle, videoSrc, url, dialogButtonTitle }) {
  const [open, setOpen] = useState(false);
  const isPartOfVideoExperiment = useTypedSelector(state => isPartOfNotificationsWithVideoExperiment(state.userState));

  const openDialog = () => {
    log({
      action: 'Notification dialog opened',
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    clearNotification(notificationKey);
  };

  if (!isPartOfVideoExperiment) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={openDialog}>
        {buttonTitle}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {dialogTitle}
          <IconButton size="small" onClick={handleClose}>
            <Tooltip title="Close">
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            <Box mb={3}>
              {description}
            </Box>
            <video
              controls
              autoPlay
              style={{ width: '100%', clipPath: 'inset(1px 1px)', borderRadius: '8px' }}
            >
              <source src={videoSrc} type="video/mp4"/>
            </video>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {url && dialogButtonTitle && <Box mb={1}>
            <ActionButton
              key={`learn_more_${notificationKey}`}
              notificationKey={notificationKey}
              url={url}
            >
              {dialogButtonTitle}
            </ActionButton>
          </Box>}
        </DialogActions>
      </Dialog>
    </>
  );
}